.mobile-myshipment-container .package-details{
    box-shadow: none;
    border-radius: 0;
}

.mobile-myshipment-container .package-table-heading{
    font-size: 0.8rem;
}

@media screen and (max-width: 992px) {
    .mobile-myshipment-container .package-table-heading{
        font-size: 1rem;
    }
}

@media screen and (max-width: 450px) {
    .mobile-myshipment-container .package-table-heading{
        font-size: 0.9rem;
    }
}