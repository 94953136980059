.div-ticket-outer{
    text-align: left;
    margin-top: 30PX;
    }
    .message-left{
        text-align: left;
        width: 50%;
        padding: 10px;
        background-color: #FED50D;
        color: #000;
        border-radius: 24px;
    }
    .message-left h2{
        color: #000!important;
    }
    .ticket-selectcss{
        background-color: #FFF2CB!important;
        box-shadow: 1px 1px 7px 0px #61616130!important;
        border-top: none !important;
        border-radius: 10px!important;
        
    }
    .w-60{
        width: 60%;
    }
    .message-dive-outer{
        display: flex;
        vertical-align: bottom;
        align-items: flex-end;
        margin-bottom: 20px;
        gap:5px;
    }
    .message-dive-outer .message-img{
        width: 45px;
        height: 45px;
        border-radius: 50%;
    }

    .message-dive-outer-right{
        display: flex;
        vertical-align: bottom;
        align-items:flex-end;
        margin-bottom: 20px;
        gap:1px;
    }
    .message-dive-outer-right .message-img{
        width: 45px;
    }

    .message-right{
        text-align: right;
        width: 50%;
        margin-left: auto;
        margin-right: 0;
        padding: 10px;
        background-color: #D4EDF1;
        color: #000;
        border-radius: 24px;
    }

    .message-dt-right{
        font-weight: 400;
        font-size: 13px;
        text-align: right;
        color: #7AA2A8;
    }

    .message-dt-left{
        font-weight: 400;
        font-size: 13px;
        color: #A48A0B;
        text-align: right;
    }
    .div-ticket-header{
        display: flex;
        /* justify-content: space-between; */
        gap: 20px;
    }
    .div-ticket-header h2{
        color: #5E5E5E;
        font-weight: 700;
        font-size: 22px;
    }
    .filesNames{
        font-size: small;
    }
    .cus-b-btn{
        background-color: var(--primary-color);
        color: #fff;
        padding:15px 30px;
        border-radius: 8px;
        font-weight: 500;
        text-transform: capitalize;
        cursor: pointer;
    }
    .table-div{
        background-color: #fff;
        padding: 20px 25px;
        margin-top: 20px;
        border-radius: 5px;
        box-shadow: 0px 0px 4px 0px #00000030;
    }
    .div-table-head div{
        color: #235EA3;
        font-size: 14px;
    }
    .div-table-head div,.div-table-content div{
        padding: 15px 15px;
        display: inline-block;
        width: 15%;
    }

    .div-table-head div:nth-child(3),.div-table-content div:nth-child(3){
       width: 30%;
    }
    .div-table-head div:last-child,.div-table-content div:last-child{
       text-align: right;
     }
    .div-table-content{
        border:1px solid #c4c4c4;
        margin: 15px 0;
        border-radius: 10px;
    }
    .div-table-content div:first-child,.div-table-head div:first-child{
        width: 150px;
    }
    .hide-sm{
        display: none!important;
    }
    .open-c{
        color: #008000;
    }
    .inprogress-c{
        color: #FFA500;
    }
    .closed-c{
        color: #808080;
    }
    .reopen-c{
        color: #FF0000;
    }
    .div-flex-1{
        display: flex;
    }
    .div-ticket-header{
        margin-bottom: 20px;
    }
    .df-w-40{
        width: 40%;
        height: 650px;
        overflow-y: auto;
    }
    .df-w-40 .m-d-flex{
        margin: 5px;
    }
    .mobile-div-flex-outer{
        border-top:1px solid #D4D4D4;
        padding-left: 10px;
        padding-top: 5px;
        border-radius: 5px;
        box-shadow: 0px 0px 4px 0px #00000030;
        margin: 10px;
    }
    .mobile-div-flex-outer .m-d-flex{
        justify-content: space-between;
    }
    .df-w-60{
        width: 60%;
        margin: 0 0 0 10px;
        position: relative;
        display: block;
        border-left: 1px solid #c4c4c4;
        padding-left: 10px;
        margin-left: 10px;
    }
    .df-w-60 .message-div{
        margin-top: 0;
    }
    .mes-div-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #E6E6E6;
        margin-bottom: 10px;
    }
    .mes-div-header h4{
        font-size: 16px;
    }
    .mes-div-header h5{
        background-color:hsl(49, 22%, 90%);
        padding:12px 25px;
        font-size: 16px;
        border-radius: 50px;
    }
    .mes-div-header .btn{
        background-color: #FED50D;
        color: #000;
        padding:15px 30px;
        border-radius: 5px;
        border: none;
       
    }
    .mes-div-header h6{
        font-size: 14px;
        color: #B4B4B4;
    }
    .mes-d-h-flex{
        display: flex;
        gap: 20px;
    }
   .no-img-cen{
    display: flex;
    justify-content: center;
    align-items: center;
   }
    .chat-img-center{
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        text-align: center;
    }
    .chat-img-center h4{
        color: #7b7b7b;
        font-size: 16px;
        margin: 20px 0;
    }
    .df-w-40 .m-d-flex div{
        width: 50%;
        margin-right: 25px;
    }
    .df-w-40 .m-d-flex div:first-child{
        color:#235EA3;
        font-size: 15px;
    }
    @media(max-width:767px){
        .hide-xs{
            display: none!important;
        }
        .hide-sm{
            display: block!important;
        }
        .mes-div-header{
            display: block!important;
        }
        .m-d-f{
            display: flex;
            justify-content: space-between;
        }
        .mes-div-header h5{
            text-align: center;
        }
        .div-ticket-header{
            display: unset;
            text-align: center;
        }
        .div-ticket-header h2{
            margin-top: 20px;
        }
        .mobile-div-flex-outer{
            background-color: #fff;
        padding: 10px;
        margin-top: 10px;
        border-radius: 5px;
        box-shadow: 0px 0px 4px 0px #00000030;
        }
        .m-d-flex{
            display: flex;
            justify-content: space-between;
        }
        .m-d-flex div:first-child{
            color: #235EA3;
            font-size: 14px;
            width: 50%;
            margin-right: 10px;
        }
        .m-d-flex div:last-child{
            text-align: right;
        }
        .cus-b-btn{
            padding: 9px 30px;
        }
        .df-w-40,.df-w-60{
            width: 100%!important;
            margin: 0!important;
        }
        .df-w-40{
            height: auto;
        }
        .div-flex-1{
            display:block!important;
        }
        /* .df-w-60{
            height: 450px;
        } */
        .message-right, .message-left{
            width: 70%;
        }
        .message-div{
            padding: 15px;
        }
        .mes-div-header .btn{
            height: 40px;
            padding: 10px 20px;
        }
        .td-div2 p{
            margin-bottom: 5px;
        }
    }