@import url('../../App.css');

.checkout-shi-cal-page-container{
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 4rem;
}

.checkout-left-section{
    flex: 1.5;
    border-right: 1px solid var(--border-color);
    padding-right: 2rem;
    margin-right: 1rem;
}

.checkout-right-section{
    flex: 1;
}

.checkout-left-section{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.checkout-calc-heading-subheading{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.package-content-address-cards{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    margin-top: 15px;
}

.package-content-address-cards .card{
    padding: 2rem;
    text-align: initial;
    height: 100%;
}

.check-shi-calc-pack-content-card{
    flex: 1;
    height: 100%;
}

.check-shi-calc-address-card{
    flex: 1;
    height: 100%;
}

.card-package-id-name-container , .card-package-content-name{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

/* package extras  */

.chekputcal-all-package-extras{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 2rem 0;
    width: 100%;
}

.extra{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    gap: 10rem;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    flex: 1;
}

.border-none{
    border: none;
}

.selected-extra{
    background: var(--primary-color);
    color: var(--white-color);
}

.selected-extra .extra-price{
    color: var(--white-color);
}

/* address */

.checkout-addresses-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
    max-height: 15rem;
}

.address-card-container{
    border: 1px solid var(--border-color);
    padding: 1rem;
    border-radius: 7px;
}

.selected-address-card-container{
    background: var(--primary-color);
    padding: 1rem;
    border-radius: 7px;
    color: var(--white-color);
}

.selected-address-card-container .address-fname-lname{
    color: var(--secondary-color);
}

.multiple-packages-ids{
    display: flex;
    gap: 10px;
    margin: 0 2rem;
}

.display-in-row{
    display: flex;
}

.package-id{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 5rem;
    background-color: var(--white-color);
    color: var(--primary-color);
    border-radius: 7px;
    border: 1px solid var(--primary-color);
}

.selected-package-id{
    background-color: var(--primary-color);
    color: var(--white-color);
}

@media screen and (max-width: 1650px) {
    .extra{
        gap: 8rem;
    }
}

@media screen and (max-width: 1500px) {
    .extra{
        gap: 6rem;
    }
}

@media screen and (max-width: 1300px) {
    .extra{
        gap: 4rem;
    }
}

@media screen and (max-width: 1210px) {
    .extra{
        gap: 2rem;
    }
}


@media screen and (max-width: 992px) {
    .checkout-shi-cal-page-container {
        flex-direction: column;
    }

    .checkout-left-section {
        border-right: none;
        padding-right: 0;
    }

    .package-content-address-cards {
        flex-direction: column;
    }

    .package-content-address-cards .card {
        width: 100%;
    }

    .extra {
        max-width: 9rem;
        flex-direction: column;
        gap: 1rem;
    }

    .checkout-shi-cal-page-container .secondary-bold-text {
        font-size: 1rem;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;       
    }
}

@media screen and (max-width: 768px) {
    .extra {
        min-width: 9rem;
        gap: 0;
    }
}

@media screen and (max-width: 576px) {
    .extra {
        min-width: 8rem;
        gap: 0;
    }
}
