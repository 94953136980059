.status-img-modal-icons-container{
    display: flex;
    justify-content: space-between; 
}

.status-img-modal-icons-container .package-img{
    display: flex;
    flex-direction: row;
}

.status-img-modal-icons-container .package-status-img {
    width: 50%;
}

.status-img-modal-icons-container .modal-icons{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.mobile-package-icon{
    width: 2.5rem;
}

.summary-mobile-icon{
    width: 3rem;
}

.mobile-package-detail-name-values{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.mobile-package-detail-container{
    margin-top: 1rem;
}

.mobile-package-detail-name-values .detail-name{
    color: var(--primary-color);
    font-size: 1rem;
}

.mobile-package-detail-name-values .value{
    font-size: 1rem;
}

.package-content-container{
    box-shadow: none !important;
}


@media screen and (max-width: 992px) {
    .mobile-package-icon{
        width: 3rem;
    }
}

@media screen and (max-width: 576px) {
    .status-img-modal-icons-container .package-status-img {
        width: 35%;
    }

    .mobile-package-icon {
        width: 2.7rem;
    }
}