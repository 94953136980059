@import url('../../../App.css');

.accordian-for-package-header-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem;
}

.accordian-body-container{
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
}

.flex01{
    flex: 0.2!important;
}

@media screen and (max-width: 768px) {
.accordian-for-package-header-container {
    font-size: 0.9rem;
    padding: 0;
  }

  .accordian-body-container{
    margin: 0rem;
  }

}

