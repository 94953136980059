@import url('../../App.css');

.dashboard-bgs{
    display: flex;
    margin: 1rem 0;
    gap: 1rem;
}

.left-side-content{
    display: flex;
    flex-direction: column;            
    flex: 1;
}
.recent-activities.recen-act-new{
    text-align: left;
    align-items: flex-end;
    position: relative;
    display: flex;
    background-color: #C3C9CC;
    justify-content: space-between;
}
.recen-act-new-inner{
    padding: 20px;
}
.recent-activities.recen-act-new h4{
    font-weight: 700;
}
.recent-activities.recen-act-new .btn-blue{
    padding: 15px 40px;
    border: 1px solid #2463af;
    box-shadow: 2px 2px 10px #636363;
}
.recent-act-image{
    width: auto;
    height: 200px;
    margin-right: 20px;
}
.shipped-and-all-packages{
    flex: 1;
    display: flex;
    gap: 1rem;
    margin-bottom: 15px;
}
/***** rk ********/
.mobile-package-detail-name-values .detail-name{
    text-align: left;
}
.mobile-package-detail-name-values .value{
    word-break: break-all;
    text-align: right;
}
.package-content-card-heading,.package-name,.package-content-card,.package-content-card-heading{
    font-size: 18px!important;
    text-transform: capitalize;
}
.package-img.pa-img1{
    align-items: center;
}
/***** rk ********/

.right-side-content{
    flex: 1;
    position: relative;
   padding: 20px;
}
.warehouse-details1{
    padding-top: 20px;
}
.activity-text, .activity-time{
    text-align: left;
    color: #838383;
}

.total-packages-count1,.shippedPackages-count1{
    color: #fff;
    font-weight: 600;
    text-align: initial;
    font-size: 1.5rem;
}
.total-shipped-packages-img,.total-packages-img {
   position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    z-index: -1;
}

.right-side-content img{
    object-fit: cover;
}
.shippedPackages-text1,.total-packages-text1{
    color: #fff;
    font-weight: 600;
    text-align: initial;
    font-size: 1.2rem;
}

.table-heading{
    font-size: 18px;
    color: var(--primary-color);
}

.total-packages-img path{
    background-color: red;
}
.all-packages , .shipped-packages{
    position: relative;
    width: 100%;
    padding: 40px;
}

.total-packages-count , .shippedPackages-count{
    position: absolute;
    top: 20%;
    left: 20%;
    font-size: 2.5rem;
    color: var(--white-color);
    font-weight: 600;
}

.total-packages-text , .shippedPackages-text{
    position: absolute;
    top: 52%;
    left: 20%;
    color: var(--white-color);
    font-weight: 600;
    text-align: initial;
} 

.secondary-button{
    background-color: var(--lite-secondary-color);
    width: 100%;
    padding: 5px;
    border-radius: 5px;
}

.activity-container{
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
}

.activities-heading-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.recent-activities{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    padding: 1rem;
    flex: 1;
}

.activity-time{
    text-align: end;
}


.activity-img{
    width: 42px;
    height: 42px;
    background: #FFEFC2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.activities-link{
    color: blue;
}

.recent-activity-img-text{
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.activities{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.flags{
    display: flex;
    position: relative;
    left: 35%;
}

.warehouse-flag{
    border: 4px solid var(--white-color);
    border-radius: 50%;
    height: 9rem;
    width: 9rem;
    opacity: 0.5;
    z-index: 1;
}

.previous-warehouse-flag{
    margin-left: -6rem;
}

.active-warehouse-flag{
    border: 4px solid var(--white-color);
    position: absolute;
    right: 100%;
    border-radius: 50%;
    height: 9rem;
    width: 9rem;
    z-index: 999;
    transform: scale(1.1);
    object-fit: cover;
}

.single-warehouse-flag{
    border: 4px solid var(--white-color);
    border-radius: 50%;
    height: 9rem;
    width: 9rem;
    z-index: 999;
    transform: scale(1.1);
    object-fit: cover;
}

.flag-img{
    border: 4px solid var(--white-color);
    border-radius: 50%;
    height: 9rem;
    width: 9rem;
}

.previous-btn{
    position: absolute;
    top: 45%;
    left: -18%;
}

.forword-btn{
    position: absolute;
    top: 45%;
    left: 100%;
    z-index: 500;
    transform: scale(1.2);
}

.warehouse-details{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 110%;
    width: 17rem;
    padding-left: 3rem;
}

.warehouses-slider-container{
    /* position: absolute; */
    top: 12%;
    left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 14rem;
    margin-top: 1rem;
    margin-left: 2rem;
}

.warehouse-fname-lname{
    font-weight: 700;
    font-size: 1.7rem;
    color: var(--white-color);
    text-align: initial;
}

.text-white-bold{
    color: var(--white-color);
    font-weight: 600;
    text-align: initial;
}

.warehouse-suiteid{
    color: var(--secondary-color);
    font-weight: 600;
    text-align: initial;
}

.chevron-icon:hover{
    transition: .5s ease-in-out;
    transform: scale(1.3);
    z-index: 999;
}


@media screen and (max-width: 1536px) {
    .total-packages-count , .shippedPackages-count{
        top: 14%;
        left: 15%;
        font-size: 2.2rem;
    }

    .table-heading{
        font-size: 16px;
    }

    .all-packages, .shipped-packages {
        position: relative;
        width: 100%;
        padding: 29px;
    }

    .previous-warehouse-flag{
        margin-left: -5rem;
    }

    .flags{
        left: 30%;
    }

    .warehouses-slider-container{
        left: 8%;
        margin-left: 0rem;
    }
    
    .total-packages-text , .shippedPackages-text{
        top: 50%;
        left: 15%;
    } 

    .recent-activities{
        padding: 10px 1rem;
    }

    .activity-container{
        gap: 7px;
    }

    .activity-img{
        width: 36px;
        height: 36px;
    }

    .activity-text , .activity-time{
        font-size: 0.9rem;
    }

    .activities-heading-link{
        margin-bottom: 10px;
    }

    .warehouse-flag, .active-warehouse-flag, .single-warehouse-flag{
        height: 8rem;
        width: 8rem;
        
    }
}

@media screen and (max-width: 1440px) {
    .activity-text , .activity-time{
        font-size: 0.85rem;
    }

    .recent-activities{
        padding: 5px 1rem;
    }

    .warehouse-flag, .active-warehouse-flag, .single-warehouse-flag{
        height: 7.5rem;
        width: 7.5rem;
    }

    .activity-img{
        width: 34px;
        height: 34px;
    }

    .left-side-content{
        gap: 10px;
    }

    .all-packages, .shipped-packages {
        position: relative;
        width: 100%;
        padding: 25px;
    }

    .shipped-and-all-packages{
        display: flex;
        gap: 1rem;
        flex: 0;
        margin-bottom: 4px;
    }

    .shippedPackages-text1, .total-packages-text1 {
        font-size: 1.1rem;
    }

    .warehouses-slider-container {
        left: 4%;
        top: 6%;
    }

    .warehouse-fname-lname {
        font-size: 1.5rem;
    }

    .warehouse-details {
        top: 108%;
    }
}

@media screen and (max-width: 1366px) {
    .table-heading{
        font-size: 14px;
    }
}

@media only screen and (max-width: 1350px) and (min-width: 1020px) {
    .shippedPackages-text1, .total-packages-text1 {
        font-size: 1rem;
      }

      .total-packages-count1, .shippedPackages-count1 {
        font-size: 1.3rem;
      }

      .warehouse-flag, .active-warehouse-flag, .single-warehouse-flag {
        height: 6rem;
        width: 6rem;
        border: 3px solid var(--white-color);
      }

      .flags {
        left: 15%;
      }

      .previous-warehouse-flag {
        margin-left: -4rem;
      }

      .text-white-bold {
        font-size: 0.9rem;
      }

      .forword-btn {
        top: 40%;
      }

      .forword-btn span{
        font-size: 1.5rem;
      }

      .activities-link {
        font-size: 0.9rem;
      }

      .warehouse-details1 {
        padding-top: 15px;
      }
}

@media screen and (max-width:1200px) {
    .shippedPackages-text1, .total-packages-text1 {
        font-size: 1rem;
    }

    .table-heading{
        font-size: 15px;
    }

    .total-packages-count1, .shippedPackages-count1 {
        font-size: 1.3rem;
    }

    .activity-text, .activity-time {
        font-size: 0.8rem;
    }

    .all-packages, .shipped-packages {
        padding: 20px 15px;
    }

    .warehouse-flag, .active-warehouse-flag, .single-warehouse-flag {
        height: 7rem;
        width: 7rem;
    }

    .activity-img {
        width: 28px;
        height: 28px;
    }
    
    .activity-icon{
        width: 0.9rem;
    }  
    
    .recent-activity-img-text {
        gap: 10px;
    }

    .table-heading {
        font-size: 12px;
    }
}

@media screen and (max-width: 992px) {

    .dashboard-bgs{
        display: flex;
        flex-direction: column-reverse;
    }
    .warehouses-slider-container{
        display: block;
    }

    .table-heading{
        font-size: 14px;
    }

    .flags{
        left: 125px;
        top: 0.7rem;
    }

    .left-side-content {
        gap: 0rem;
    }

    .all-packages, .shipped-packages {
        padding: 40px 15px;
    }

    .shippedPackages-count1, .shippedPackages-text1, .total-packages-count1, .total-packages-text1{
        font-size: 20px;
    }
    .total-packages-count1,.shippedPackages-count1{
        font-size: 35px;
    }

    .warehouse-flag, .active-warehouse-flag, .single-warehouse-flag{
        height: 8rem;
        width: 8rem;
    }

    .previous-warehouse-flag {
        margin-left: -4rem;
    }

    .forword-btn {
        top: 40%;
        left: 70px;
    }    

    .shipped-and-all-packages {
        gap: 10px;
        margin-bottom: 1rem;
    }

    .active-warehouse-flag, .single-warehouse-flag, .previous-warehouse-flag{
        border: 2px solid var(--white-color);
    }

    .warehouse-details {
        top: 130%;
        padding-left: 2rem;
    }

    .right-side-part{
        padding: 11px;
    }

    

    .total-packages-count, .shippedPackages-count {
        top: 12%;
        left: 11%;
        font-size: 1.1rem;
    }

    .total-packages-text, .shippedPackages-text {
        top: 43%;
        left: 11%;
        font-size: 0.6rem;
    }

    .profile-container:hover .profile-hover-content{
        z-index: 9999;
    }

    .chevron-icon{
        font-size: 1.5rem;
    }

    .warehouse-fname-lname {
        font-size: 2rem;
    }

    .warehouse-address, .warehouse-suiteid, .warehouse-city-state, .warehouse-zip-country{
        font-size: 1rem;
    }

    .activity-img{
        width: 1.7rem;
        height: 1.1rem;
    }

    .activity-icon{
        width: 0.7rem;
    }

    .recent-activity-img-text {
        gap: 7px;
      }

    .activity-text, .activity-time{
        font-size: 0.6rem;
    }

    .activities-heading, .activities-link{
        font-size: 0.7rem;
    }

    .recent-activities {
        padding: 10px;
    }

    .single-flag {
        margin-right: 6rem;
        margin-top: 1rem;
        margin-bottom: -0.8rem;
    }

    .single-warehouse-flag{
        margin-right: 2rem;
    }

    .right-side-content {
        padding: 50px;
    }
}

@media screen and (max-width: 768px) {

    .dashboard-bgs{
        display: flex;
        flex-direction: column-reverse;
    }
    .warehouses-slider-container{
        display: block;
    }

    .flags{
        left: 102px;
        top: 0.7rem;
    }

    .left-side-content {
        gap: 0rem;
    }

    .all-packages, .shipped-packages {
        padding: 40px 15px;
    }

    .shippedPackages-count1, .shippedPackages-text1, .total-packages-count1, .total-packages-text1{
        font-size: 16px;
    }
    .total-packages-count1,.shippedPackages-count1{
        font-size: 30px;
    }

    .warehouse-flag, .active-warehouse-flag, .single-warehouse-flag{
        height: 6rem;
        width: 6rem;
    }

    .previous-warehouse-flag {
        margin-left: -3rem;
    }

    .forword-btn {
        top: 45%;
        left: 55px;
    }

    .shipped-and-all-packages {
        gap: 10px;
        margin-bottom: 1rem;
    }

    .active-warehouse-flag, .single-warehouse-flag, .previous-warehouse-flag{
        border: 2px solid var(--white-color);
    }

    .warehouse-details {
        top: 130%;
        padding-left: 2rem;
    }

    .right-side-part{
        padding: 11px;
    }

    

    .total-packages-count, .shippedPackages-count {
        top: 12%;
        left: 11%;
        font-size: 1.1rem;
    }

    .total-packages-text, .shippedPackages-text {
        top: 43%;
        left: 11%;
        font-size: 0.6rem;
    }

    .profile-container:hover .profile-hover-content{
        z-index: 9999;
    }

    .chevron-icon{
        font-size: 1rem;
    }

    .warehouse-fname-lname {
        font-size: 1.5rem;
    }

    .warehouse-address, .warehouse-suiteid, .warehouse-city-state, .warehouse-zip-country{
        font-size: 0.9rem;
    }

    .activity-img{
        width: 1.7rem;
        height: 1.1rem;
    }

    .activity-icon{
        width: 0.7rem;
    }

    .recent-activity-img-text {
        gap: 7px;
      }

    .activity-text, .activity-time{
        font-size: 0.6rem;
    }

    .activities-heading, .activities-link{
        font-size: 0.7rem;
    }

    .recent-activities {
        padding: 10px;
    }

    .single-flag {
        margin-right: 6rem;
        margin-top: 1rem;
        margin-bottom: -0.8rem;
    }

    .single-warehouse-flag{
        margin-right: 2rem;
    }

    .right-side-content {
        padding: 30px;
    }
}

@media screen and (max-width: 576px) {

    .flags{
        left: 90px;
        top: 0.7rem;
    }

    .warehouse-flag, .active-warehouse-flag, .single-warehouse-flag{
        height: 5rem;
        width: 5rem;
    }

    .right-side-content {
        padding: 20px;
    }

    .all-packages, .shipped-packages {
        padding: 20px 15px;
    }

    .forword-btn {
        top: 40%;
        left: 35px;
    }

    .chevron-icon {
        font-size: 1.2rem;
    }

    .shippedPackages-count1, .shippedPackages-text1, .total-packages-count1, .total-packages-text1{
        font-size: 15px;
    }
    .total-packages-count1,.shippedPackages-count1{
        font-size: 25px;
    }

    .total-shipped-packages-img,.total-packages-img {
        object-fit: fill;
    }
}

@media screen and (max-width: 450px) {

    .right-side-content img{
        object-fit: cover;
    }

    .flags{
        left: 70px;
        top: 0.7rem;
    }

    .dashboard-bgs{
        gap: 0rem;
    }

    .warehouse-flag, .active-warehouse-flag, .single-warehouse-flag{
        height: 4rem;
        width: 4rem;
    }

    .right-side-content {
        padding: 20px;
    }

    .all-packages, .shipped-packages {
        padding: 20px 15px;
    }

    .forword-btn {
        top: 40%;
        left: 20px;
    }

    .chevron-icon {
        font-size: 1.2rem;
    }

    .shippedPackages-count1, .shippedPackages-text1, .total-packages-count1, .total-packages-text1{
        font-size: 11px;
    }
    .total-packages-count1,.shippedPackages-count1{
        font-size: 19px;
    }

    .warehouse-fname-lname {
        font-size: 1rem;
    }

    .warehouse-address, .warehouse-suiteid, .warehouse-city-state, .warehouse-zip-country{
        font-size: 0.6rem;
    }

    .warehouses-slider-container{
        margin-top: 0rem;
        margin-left: 0.5rem;
    }
}


