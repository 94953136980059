@import url('../../../App.css');

.accordian-for-package-header-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem;
}

.accordian-body-container{
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
}

.flex01{
    flex: 0.2!important;
}
.flex10{
    flex: 0.8!important;
}
.incomplete-suite{
    font-size: 12px;
}