@import url('../../App.css');

.login-container{
    background: var(--primary-color);
    min-height: 100vh;
    max-height: auto;
    position: relative;
}

/* body{
    background: var(--primary-color);
} */

.register-container{
    background: var(--primary-color);
    min-height: 100vh;
    max-height: auto;
    position: relative;
}

.login-card{
    position: absolute;
    /* min-height: 80%; */
    max-height: fit-content;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    box-shadow: 0px 0px 134px rgba(0, 0, 0, 0.43);
    border-radius: 18px;
    margin: 1rem 0;
}

.login-forget-password-link{
    display: flex;
    justify-content: flex-end;
}


.forget-password-content{
    padding: 10rem 4rem !important;
}


.login-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin: 1.5rem 0;
}

.login-content{
    display: table;
    flex-direction: column;
    width: 40%;
    max-height: 100%;
    background: var(--white-color);
    text-align: initial;
    line-height: 2.4;
    box-sizing: border-box;
    padding: 6rem 3rem;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    height: 100%;
}

.login-heading{
    font-size: 2rem;
    font-weight: 600;
}

.button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid #C4C4C4;
    border-radius: 34.3838px;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;
    transition: .4s all ease-in-out;
    width: 100%;
}

.facebook-button-text, .google-button-text{
    font-size: 1rem;
}

.login-with-facebook-button , .login-with-google-button{
    background: var(--primary-color);
    color: var(--white-color);
    border: none;
}

.login-img{
    position: absolute;
    top: 70%;
    left: 75%;
    transform: translate(-65% , -70%);
    width: 38%;
}

.colored-text{
    color: var(--secondary-color);
}

.common-text , .colored-text{
    font-size: 1.4rem;
}

.login-text-container{
    position: absolute;
    top: 15%;
    left: 77%;
    color: #fff;
    transform: translate(-65% , -70%);
    z-index: 999;
    width: 45%;
}

.signup-text-container{
    position: absolute;
    top: 15%;
    left: 75%;
    color: #fff;
    transform: translate(-65% , -70%);
    z-index: 999;
    width: 32%;
}

.cross-line{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 30% 40% 30%);
    align-items: center;
    color: #C4C4C4;
    column-gap: 8px;
    text-align: center;
    font-size: 1rem;
    margin-top: 2rem;
}

.cross-line::before, .cross-line::after{
    content: '';
    height: 2px;
    background-color: #C4C4C4;
    display: block;
}

.label{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    font-weight: 600;
}


.input{
    width: 100%;
    padding: 11px;
    border-radius: 34px;
    border: 1px solid #C4C4C4;
    background: #fff;
    font-size: 1rem;
}

.remember-me-forgot-password{
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
}

.checkbox-input{
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.link{
    color: blue;
    font-weight: 501;
}

.validated-form-label-input-container{
    padding: 20px;
    width: 100%;
}

.form-button{
    width: 100%;
    color: var(--white-color);
    font-weight: 700;
    background-color: var(--primary-color);
    margin-top: 1rem;
}

.validated-login-input .input{
    padding: 18px;
}

 /* Register Page Code */
.first-last-name-input{
    display: flex;
    gap: 1rem;
}

.select-input{
    padding: 16px;
}

.signup-with-google-facebook{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 10px;
}

.register-content{
    line-height: 1.1;
   padding: 5rem 2rem;
    /* padding: 1rem 3rem 1rem 3rem; */
}

.register-content .input{
    padding: 8px ;
    margin-top: 5px;
}

.password-label-input{
    position: relative;
}

.eye-icon{
    position: absolute;
    bottom: 17%;
    right: 5%;
    font-size: 1.8rem;
    cursor: pointer;
}

.register-container .eye-icon{
    position: absolute;
    bottom: 15%;
    right: 5%;
    font-size: 1.4rem;
    cursor: pointer;
}

.countries-in-modal{
    margin: 1rem auto !important;
    width: 100%;
    display: flex;
  }

  .input-with-toggle-password{
    width: 100%;
  }

@media screen and (max-width:1536px) {
    
    .register-container{
        min-height: 105vh;
    }

    .login-content{
        padding: 5rem 3rem;
    }

    /* .login-content{
        padding: 1rem 2rem 0 2rem;
    } */

    .register-content .input {
        padding: 1px 10px;
        margin-top: 5px;
        font-size: 0.9rem;
    }

    .button{
        padding: 7px;
    }

    .input{
        padding: 7px;
        padding-left: 20px;
    }

    .label{
        margin: 0;
    }

    .cross-line{
        font-size: 0.8rem;
    }

    .cross-line::before, .cross-line::after{
        height: 1px;
    }

    .register-content{
        line-height: 2;
    }

    .login-text-container{
        width: 53%;
    }
    
    .signup-text-container{
        width: 35%;
    }

}

@media screen and (max-width:1440px){
    .register-content .input {
        padding: 1px 10px;
        margin-top: 5px;
    }

    .login-heading {
        font-size: 1.8rem;
        font-weight: 600;
    }

    /* .login-container{
        min-height: 110vh;
    }

    .register-container{
        min-height: 130vh;
    } */

    .login-text-container{
        top: 20%;
    }

    .login-card .forget-password-content{
        padding: 10rem 2rem !important;
    }
}

@media screen and (max-width: 1366px){
    .register-container{
        min-height: 130vh;
    }

    .login-container{
        height: 110vh;
    }
}

@media screen and (max-width:1200px){

    .login-container{
        height: 100vh;
    }

    .register-container{
        height: 100vh;
    }

    .login-card {
        /* height: 80%; */
        width: 95%;
    }

    .register-container .login-card {
        /* height: 90%; */
        width: 95%;
    }

    .register-container .login-content {
        padding: 3rem;
    }

    .login-content{
        width: 43%;
    }

    .button{
        padding: 6px;
    }

    .input{
        padding: 4px;
        padding-left: 20px;
    }

    .cross-line{
        font-size: 13px;
    }

    .cross-line::before, .cross-line::after{
        height: .6px;
    }

    .checkbox-text{
        font-size: 1rem;
    }

    .link{
        font-size: 14px;
    }

    .login-with-facebook-button, .login-with-google-button{
        gap: 4px;
    }
    
    .login-text-container{
        width: 46%;
        top: 18%;
    }
    
    .signup-text-container{
        width: 42%;
    }

    .common-text , .colored-text{
        font-size: 1.1rem;
    }
    
}

@media only screen and (max-width: 1017px) and (min-width: 980px)  {
    .login-content {
        padding: 3rem;
    }
}

@media screen and (max-width:900px){

    .login-container{
        padding: 1rem;
    }

    .login-card{
        height: 100%;
        width: 100%;
        box-shadow: none;
    }

    .login-buttons{
        margin: 10px 0;
    }

    .login-card .forget-password-content{
        padding: 4rem 2rem !important;
    }

    .login-content{
        width: 47%;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        line-height: 1.8;
        padding-top: 3rem;
        /* display: grid; */
        justify-content: center;
        padding-bottom: 10rem;
    }

    .label{
        font-size: 14px;
        margin-top: 5px;
    }

    .login-heading{
        font-size: 1.8rem;
    }

    .login-img{
        top: 60%;
        left: 78%;
    }

    .input{
        font-size: 14px;
        padding: 10px;
        padding-left: 20px;
    }

    .cross-line{
        font-size: 12px;
    }


    .checkbox-text{
        font-size: 13px;
    }

    .link{
        font-size: 13px;
    }

    .signup-text-container{
        left: 80%;
        top: 27%;
    }

    .login-text-container{
        left: 80%;
        top: 30%;
    }

    .register-content{
        padding-bottom: 5rem;
    }

    .register-container .eye-icon{
        position: absolute;
        bottom: 5%;
        right: 5%;
        font-size: 1.4rem;
        cursor: pointer;
    }

}

@media screen and (max-width: 992px){
    .login-container{
        height: max-content;
    }

    .register-container{
        background: var(--primary-color);
        min-height: 140vh;    
        max-height: auto;
        position: relative;
    }

    .login-card{
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        height: max-content;
        box-shadow: none;
    }

    .login-content {
        height: max-content;
        width: 65%;
        z-index: 10;
        border-bottom-right-radius: 18px;
        border-top-right-radius: 18px;
        border-bottom-left-radius: 18px;
        border-top-left-radius: 18px;
        line-height: 2.1;
        padding-bottom: 1rem;
        display: table;
      }

    .register-content{
        margin-top: 4rem;
        margin-bottom: 5rem;
    }

    .register-content .register-img{
        top: -20%;
    }

    .register-content .input{
        padding: 8px;
    }

    .cross-line{
        font-size: 13px;
        margin-top: 1rem;
    }

    .img-content{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
    }

    .input{
        padding: 14px;
    }

    .button{
        padding: 8px;
    }


    .link{
        font-size: 15px;
    }

    .login-img{
        position: absolute;
        top: -32%;
        left: 50%;
        transform: translate(-50%,0);
        width: 70%;
    }

    .register-container .login-img{
        position: absolute;
        top: -17%;
        left: 50%;
        transform: translate(-50%,0);
        width: 70%;
    }

    .login-text-container, .signup-text-container{
        display: none;
    }

    .password-eye-icon {
        top: 2.9rem !important;
    }

    .forget-password-content{
        padding: 2rem !important;
    }
}

@media screen and (max-width: 768px){
.login-content {
    width: 70%;
  }

}

@media screen and (max-width: 600px){
    .login-content {
        padding: 5rem 2rem;
        width: 95%;
    }

    .link {
        font-size: 14px;
    }

    .register-container .login-content {
        padding: 3rem 2rem;
    }
}

@media screen and (max-width: 500px){
    .register-container .login-card {
        height: 90%;
        width: 100%;
    }
}


@media screen and (max-width: 430px){
    .link {
        font-size: 14px;
    }

    .register-content{
        padding: 2rem 1.5rem;
    }

    .login-content{
        padding: 2rem 1.5rem;
    }

    .register-container .login-content {
        padding: 3rem 1rem;
    }
}