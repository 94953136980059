.create-ticket {
    text-align: left;
    margin-top: 15px;
}
.create-ticket h2{
    color: #5E5E5E;
    font-weight: 700;
    font-size: 22px;
    text-align: left;
}
.create-t-form{
    background-color: #fff;
    padding: 20px 25px;
    margin-top: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px #00000030;
    width: 40%;
}
.form-label{
    margin-bottom: 0;
    margin-top: 10px;
}
label{
    font-size: 16px;
    color: #3F3F3F;
}
.form-control{
    margin: 4px 0;
    appearance: auto;
    padding: 7px;
}
.cus-btn{
    background-color: #2461A9;
    color: #fff !important;
    padding: 10px 30px !important;
    display: inline-block !important;
    border-radius: 10px !important;
    margin-top: 10px !important;
}

@media(max-width:767px){
    .create-t-form{
        width: 100%;
    }
    
    .cus-btn{
        display: block;
        text-align: center;
    }
}