@import url('../App.css');

/* addAddressModal CSS */
.modal-container{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    z-index: 99999;
}

.confirmation-modal-normal-text{
    margin: 1rem 0;
}

.primary-button{
    padding: 6px 1rem;
    color: var(--white-color);
    background-color: var(--primary-color);
    border-radius: 7px;
    font-weight: 600;
}

.primary-border-button{
    padding: 6px 1rem;
    color: var(--primary-color);
    background-color: var(--white-color);
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    font-weight: 600;
}

.modal-heading{
    display: flex;
    justify-content: space-between;
}

.modal-heading-name{
    font-size: 1.7rem;
    font-weight: 600;
    color: var(--primary-color);
}

.modal-form-buttons{
    display: flex;
    gap: 1rem;
}

.modal-form-button{
    padding: 5px 1rem;
    border-radius: 7px;
    margin-top: 1rem;
}

.secondary-white-button{
    color: var(--white-color);
    background: var(--secondary-color);
    border: none;
}

.primary-border-button{
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}

.modal-container .form-label{
    font-weight: 500;
    color: #000;
}

.modal-container .form-input{
    padding: 10px;
}

.modal-close-button{
    z-index: 1000;
}

/* customization modal */

.customizations-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
}

.customization-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    background: #F8FBFF;
    min-width: 12rem;
    min-height: 6rem;
    border-radius: 7px;
    width: 31%;
}
.payment-img{
    width: 154px;
    height: 154px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.payment-card-succ {
    /* width: 650px; */
    /*height: 770px;*/
    padding: 30px;
    border-radius: 30px;
    background: #FFF;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.07);
    margin-top: 40px!important;
    margin: 0px auto;
}
.payment-card-succ-div {
    width: 650px;
    text-align: center;
}
.payment-card-succ-div img{
    margin-bottom: 20px;
}
.payment-card-succ-div .payment-wait,.payment-card-succ-div .payment-wait-msg{
    margin: 20px;
}
.payment-card-succ-div .payment-wait-msg{
    font-size: 16px;
}
.payment-card-succ-div .payment-dont{
    font-size: 16px;
    margin: 20px;
}
.payment-card-succ.payment-card-succ-div{
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}
.payment-wait{
    font-family: Montserrat;
    font-size: 28px;
    font-weight: 700;
    line-height: 34.13px;
    /* text-align: left; */
    color: #5E5E5E;

}
.payment-wait-msg{
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;
    text-align: center;
    color: #000000;
}

.payment-fail-msg{
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;
    text-align: center;
    color: #f00606;
}
.payment-sucess-msg{
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;
    text-align: center;
    color:#2461A9;
}
.payment-time-msg{
    font-family: Montserrat;
    font-size: 28px;
    font-weight: 700;
    line-height: 34.13px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #F7BD16;
}
.payment-dont{
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: center;
    color: #000000;

}
.selected-customization-container{
    background: var(--primary-color);
    color: var(--white-color);
}

.customization-heading{
    font-size: 1.2rem;
    font-weight: 300;
    text-align: center;
    color: var(--primary-color);
}

.selected-customization-container .customization-heading{
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--white-color);
}

.customization-img{
    width: 9rem;
}

.total-heading{
    font-size: 1.3rem;
    font-weight: 500;
    text-align: end;
}

.correct-submit-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 3rem;
    background: var(--primary-color);
    border-radius: 7px;
    width: fit-content;
    margin: 0 auto;
}

.color-white{
    color: var(--white-color);
}

.bold-primary-text{
    font-weight: 500;
}

/* request photos modal */

.request-photo-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: -2rem;
}

.request-photo-close-button{
    text-align: end;
}

.img-black-bold-heading{
    font-size: 1.3rem;
    font-weight: 700;
}

.send-request-button{
    margin-top: 1rem;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 4rem;
    border-radius: 7px;
    margin: 2rem auto 0 auto;
    font-weight: 600;
}

/* Questionnaire modal */

.forms{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    margin: 1rem 0;
}

.package-total-price{
    text-align: end;
    margin-bottom: 2rem;
    font-weight: 600;
}

.questionnaire-terms-and-conditions{
    color: var(--gray-text-color);
    font-size: .9rem;
    margin-bottom: 1rem;
}

.questionnaire-note{
    font-weight: 500;
}


/* add payment method modal */

.card-form-billing-address{
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
}

.card-form-container{
    flex: 1;
}

.card-form-heading{
    font-weight: 600;
    color: var(--primary-color);
}

.billing-addresses-container .payment-addresses-container{
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 0;
    max-height: 21.5rem;
}

.modal-card-details-form{
    border: 1px solid var(--border-color);
    padding: 2rem 1rem;
    border-radius: 7px;
    line-height: 2.2;
}

.add-new-address-button{
    color: var(--primary-color);
    font-weight: 600;
    text-align: end;
    margin-top: -10px;
    cursor: pointer;
}

.card-and-address-toggle-form{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 7px;
    padding: 1rem;
}

.card-and-address-toggle-form .modal-card-details-form{
    border: none;
    padding: 0;
    flex: 0.8;
    border-right: 1px solid var(--border-color);
    padding-right: 1rem;
    border-radius: 0%;
}

.card-and-address-toggle-form .modal-address-form{
    flex: 1;
}

.back-to-addresses-link{
    color: var(--primary-color);
    text-align: end;
    cursor: pointer;
}

.space-between{
    display: flex;
    justify-content: space-between;
}

/* summary-modal */

.modal-package-summary{
    display: flex;
    gap: 3rem;
}

.summary-left-content{
    flex: 0.4;
}

.summary-right-content{
    flex: 1;
}

.summary-right-content .flex04{
    flex: 0.4 !important;
}

.primary-medium-text{
    font-weight: 600;
    font-size: 1.1rem;
    color: var(--primary-color) !important;
}

.summary-right-content .package-table-heading{
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 5px;
}

.summary-right-content .package-table-headings{
    gap: 1rem;
}

.gift-wrap-div-img{
    background: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: 7px;
}

.modal-summary-package-details{
    margin-top: 1rem;
}

.modal-summary-package-details .package-detail{
    color: var(--primary-color);
}

.black-text{
    color: black;
    font-weight: 500;
}

.primary-white-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 7px 5rem;
    border-radius: 7px;
    background: var(--primary-color);
    color: var(--white-color);
    margin: 0 auto;
}

/* refer and earn modal */

.refer-code-copy-container{
    margin: 1rem 0;
}

.referral-input-button{
    display: flex;
    gap: 10px;
}

.referral-input{
    border: 2px solid var(--secondary-color);
    border-radius: 4px;
    padding-left: 10px;
    font-weight: 600;
    color: var(--primary-color);
}

.referral-input:focus{
    border: 2px solid var(--secondary-color);
    border-radius: 4px;
}

.copy-icon-div{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    color: var(--white-color);
    border-radius: 5px;
    cursor: pointer;
}

.copy-icon{
    font-size: 1.5rem;
    padding: 8px 12px; 
}

.bold-text{
    font-weight: 500;
}

.copied-text{
    position: absolute;
    background: var(--primary-color);
    color: var(--white-color);
    bottom: 120%;
    padding: 5px 1rem;
    border-radius: 7px;
    z-index: 999;
}

.point{
    position: absolute;
    bottom: 110%;
    transform: rotate(45deg);
    height: 1rem;
    width: 1rem;
    background: var(--primary-color);
}

.md-bold-text{
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--primary-color);
}

.threeSteps{
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
}

.tbd-content{
    margin: 1rem;
}

.ml1{
    margin-left: 1.5rem;
}

/* popup modal css */

.popup-div{
    position: fixed;
    top: 10%;
    left: 50%;
    width: 100vw;
    min-height: 100vh;
    max-height: auto;
    transform: translate(-50% , -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
}

.popup-div-active{
    position: fixed;
    left: 50%;
    top: 45%;
    min-height: 100vh;
    max-height: auto;
    width: 100vw;
    height: auto;
    transform: translate(-50% , -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    transition: 0.5s;
    visibility: visible;
    z-index: 20000;
    opacity: 1;
}

.popup{
    position: relative;
    max-width: 70vw;
    max-height: 90vh;
    background: #fff;
    border-radius: 5px;
    box-shadow: 5px 15px 15px rgba(0,0,0,0.2);
    transition: 0.5s;
}

.close-button{
    display: none;
}

.popup:hover .close-button{
    display: block;
    position: absolute;
    z-index: 10000;
    top: 0;
    right: 3%;
    font-size: 3rem;
    cursor: pointer;
}

.popup-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border-radius: 7px;
}

 /* slider modal */

 .slider-container{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 8888;
    background-color: rgba(15, 15, 15, 0.4);
 }

 .slider-card{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate( -50% ,-50%);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
 }
 .payment-gray{
    font-size: 15px;
    color: #969696;
 }
 .update-btn{
    background-color: #2461A9;
    padding: 15px 30px;
    color: #fff;
    font-size: 16px;
    display: inline-block;
    text-decoration: none;
    margin-top: 30px;
    cursor: pointer;
 }

 @media screen and (max-width: 992px) {

    .customization-container {
        min-width: 10rem;
        min-height: 6rem;
    }

    .card-form-billing-address {
        flex-direction: column;
    }

    .card-and-address-toggle-form {
        flex-direction: column;
    }

    .card-and-address-toggle-form .modal-card-details-form {
        border-radius: 0%;
        border: none;
        width: 100%;
    }

    .popup-div-active{
        position: fixed;
        left: 50%;
        top: 35%;
        min-height: 100vh;
        max-height: auto;
        width: 100vw;
        height: auto;
        transform: translate(-50% , -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(2px);
        transition: 0.5s;
        visibility: visible;
        z-index: 20000;
        opacity: 1;
    }

    .popup{
        position: relative;
        max-width: 99vw;
        max-height: 90vh;
        background: #fff;
        border-radius: 5px;
        box-shadow: 5px 15px 15px rgba(0,0,0,0.2);
        transition: 0.5s;
    }


    .modal-container {
        padding: 1rem;
    }

    .img-black-bold-heading {
        font-size: 0.7rem;
    }

    .photos-request-modal-image{
        width: 15%;
    }

    .request-photo-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
        margin-top: -2rem;
    }

    .photos-modal-textarea{
        height: 8rem!important;
        font-size: 0.8rem!important;
    }

    .package-total-price{
        font-size: 0.7rem;
    }

    .send-request-button {
        padding: 7px 1rem;
        margin: 1rem auto 0 auto;
        font-size: 0.7rem;
      }

    .modal-heading-name {
        font-size: 1rem;
    }

    .questionnaire-form {
        padding: 10px;
    }

    .form-label, .form-input{
        font-size: 0.7rem;
    }

    .questionnaire-terms-and-conditions{
        font-size: 0.8rem;
    }

    .questionnaire-note{
        font-size: 0.7rem;
        margin-bottom: 1rem;
    }

    .reduce-form {
        height: 1rem!important;
        width: 1rem!important;
        font-size: 1rem!important;
        margin-right: -10px;
    }

    .feedback-text{
        font-size: 0.5rem;
    }

    .gift-wrap-div-img {
        padding: 10px;
    }

    .summary-gift-wrap-image{
        width: 2rem;
        height: 2rem;
    }

    .package-table-heading {
        font-size: 0.8rem;
    }

    .secondary-bold-text {
        font-size: 0.8rem;
    }

    .package-table-data, .package-detail {
        font-size: 0.8rem;
    }


    .primary-white-button {
        padding: 7px 2rem;
    }

 }
 @media screen and (max-width: 767px) {
    .payment-card-succ-div{
       width: 90%;
    }
    .payment-card-succ.payment-card-succ-div{
       padding: 10px;
    }
    .popup-div-active{
       top: 50%;
    }
    .payment-card-succ-div img{
        width: 100px;
    }
    .payment-card-succ-div .payment-wait, .payment-card-succ-div .payment-wait-msg{
        margin: 10px;
    }
   }

   .consol-modal .modal-heading-name{
    font-size: 20px;
   }
   .consol-modal h4{
    font-size: 18px;
   }
   .consol-modal-buton{
    display: flex;
    gap: 15px;
    justify-content: center;
   }
   .consol-modal .primary-white-button{
    margin: 0;
   }
 .consol-modal .modal-heading,.consol-modal .modal-package-summary{
        margin-bottom: 20px;
     }
     .primary-white-button.cursor.btn-red{
        background-color: #f00;
     }
     .consol-modal .modal-heading{
        display: block;
        text-align: center;
     }
     