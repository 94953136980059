@import url('../../App.css');
@media(max-width:767px){
    .payment-option,.paypal-method{
        width: 50%!important;
    }
    .paypal-method,.payment-option{
        height: 10rem!important;
        padding: 10px;
    }
    .paypal-buttons{
        min-width: 125px!important;
    }
}
.card-box-text,.paypal-box-text{
    text-align:center;
}

.addressAndPaymentPage-container{
    display: flex;
    flex-direction: column;
    text-align: initial;
    margin-top: 1rem;
}
.payment-method-card-img{
    height: 67px;
}
.payment-method-page-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.address-and-order-summary-container{
    display: flex;
    gap: 1rem;
    height: 100%;
    margin-bottom: 4rem;
}

.payment-address-payments-container{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.payment_info_form{
    width: 100%;
}

.order-summary-container{
    width: 30%;
}

.box-shadow-none{
    box-shadow: none;
}

.low-opacity{
    opacity: 0.5;
}

.payment-addresses-container{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 1rem 0;
    overflow: auto;
    scroll-behavior: smooth;
}

.payment-cards-container{
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
    overflow: auto;
    scroll-behavior: smooth;
}

/* summary-container */

.summary-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    line-height: 2.5;
    height: fit-content;
}

.coupon-container{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.coupon-apply-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 0 2rem;
    border-radius: 7px;
}

.summary-items-container{
    display: flex;
    justify-content: space-between;
}

.summary-total{
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 1.2rem;
}

.summary-payment-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 7px;
    font-weight: 600;
}

.total-split-amounts{
    font-weight: 400;
    font-size: 1rem;
    margin-top: -1.5rem;
}

/* amazon pay payment method  */

.third-party-payments-container{
    margin-top: 1rem;
}

.third-party-payments-container #formBasicEmail{
    padding: 1rem;
}

.img-wallet-money{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wallet-money{
    font-size: 1.4rem;
    font-weight: 600;
}

.amazon-payment-form{
    margin-top: 2rem;
}

.forgot-text{
    color: blue;
    margin-top: -10px;
    cursor: pointer;
}

.amazonpay-submit-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 4rem;
    background: var(--secondary-color);
    color: var(--white-color);
    border: none;
    border-radius: 7px;
    margin-top: 2rem;
    font-weight: 600;
}

.available-coupons-link{
    color: blue;
    font-size: 0.9rem;
    margin-top: -1.5rem;
    cursor: pointer;
}

.addressAndPaymentPage-container .order-summary-container{
    height: fit-content;
}

@media screen and (max-width: 1536px) {
    .temp-card{
        margin-left: -1rem;
    }
}

@media screen and (max-width: 1536px) {

    .addressAndPaymentPage-container .order-summary-container {
        width: 100%;
    }

    .payment-method-card-img{
        width: 100%;
        height: 67px;
    }
    .paypal-method {
        width:  100%;
    }
    .card-box-text{
        font-size: 0.9rem;
    }

    .paypal-box-text{
        font-size:  0.9rem;
        padding: 10px;
    }
}

@media screen and (max-width: 1536px) {
    .payment-method-card-img {
        width: 100%;
        height: 67px;
        aspect-ratio: 1/1;
    }

    .paypal-method {
        width: 18rem;
        height: 11rem;
        aspect-ratio: 1/1;
    }

    .paypal-box-text{
        font-size:  0.9rem;
        padding: 10px;
    }
    .addressAndPaymentPage-container .order-summary-container {
        width: 50%;
    }

    .cardTop {
        padding: 0px;
        background-color: #fff;
    }

    .addressAndPaymentPage-container .form-label, .form-input {
        font-size: 0.9rem;
    }

    .package-table-data, .package-detail {
        font-size: 0.9rem;
    }
}


@media screen and (max-width: 992px) {
    .address-and-order-summary-container {
        display: flex;
        gap: 1rem;
        height: 100%;
        flex-direction: column;
        margin-bottom: 0;
    }

    .payment-method-card-img {
        width: 100%;
        height: 67px;
        aspect-ratio: 1/1;
    }

    .paypal-method {
        width: 18rem;
        height: 11rem;
        aspect-ratio: 1/1;
    }
    .addressAndPaymentPage-container .order-summary-container {
        width: 100%;
    }

    .payment-address-payments-container {
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .package-table-data, .package-detail {
        font-size: 0.8rem;
    }
}
