.paymentMethodheadding{
    font-weight: bold;
    margin-bottom: 30px;
    color: #2461A9;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .totalcss{
    font-size: 18px;
    color: #222;
  }
  .paymentMethod{
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 7%);
    border-radius: 0.5rem;
    margin: 20px;
  }
  .payBtnStyle {
    margin-top: 30px;
    background-color: #337ace;
    width: 100%;
  }
  .checkBoxCss{
    position: absolute;
    right: 36px;
  }
  .cardname {
    display: flex;
    margin-top: -28px;
    margin-left: -20px;
    color: #9c9c9c;
    font-family: "Montserrat";
    font-size: 16px;
  }
  .imagePaymentMob{
    width: 59px;
  }
  .paymentSecond{
    width: 100%;
  }
  .custmCard{
    border-radius: 0.5rem;
    /* box-shadow: 0px 4px 20px rgb(0 0 0 / 7%); */
    padding: 20px;
    border: 0px;
  }
  .custmEditCard{
    border-radius: 0.5rem;
    /* box-shadow: 0px 4px 20px rgb(0 0 0 / 7%); */
    padding: 20px;
    border: 0px;
    /* height: 459px; */
  }
  .checkinput {
    width: 20px;
    height: 20px;
    margin-left: -50px;
  }
  .cardlabelcvv {
    margin-left: 10px;
    color: #9c9c9c;
    font-family: "Montserrat";
    font-size: 12px;
  }
  .pStyle {
    color: grey;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .paymentH1 {
    color: white;
    font-weight: bold;
  }
  
  .cardBG {
    /* background-color: #337ACE; */
    background: rgb(255, 251, 238);
    padding-top: 20px;
    padding-bottom: 30px;
    border-radius: 10px;
    text-align: center;
  }
  
  .confirmTitle {
    margin-top: 30px;
    padding-bottom: 30px;
    color: #F7BD16;
    font-weight: bold;
    border: none;
  }
  
  .successBtn {
    width: 20%;
    background-color: #18477D;
    border-radius: 20px;
  }
  
  .cardPayDiv {
    margin-top: 40px;
  }
  .inputvalue {
    text-align: left;
    padding: 7px;
    width: 84%;
    font-size: 13px;
    display: flex;
    align-items: left;
    border: 2px solid #5091de;
    margin-bottom: 10px;
  }
  .ticksymbol {
    content: "\2713";
    color: green;
    font-weight: bold;
  }
  .inputvalue2 {
    text-align: left;
    padding: 7px;
    width: 30%;
    font-size: 13px;
    display: flex;
    align-items: left;
    border: 2px solid #5091de;
    margin-bottom: 10px;
  }
  .inputvalue3 {
    text-align: left;
    padding: 7px;
    width: 50%;
    font-size: 13px;
    display: flex;
    border: 2px solid #5091de;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  
  .imgpadding {
    padding: 10px 0px;
    max-width: 100%;
    border-radius: 0.6rem;
    width: 160px;
    margin-top: 13px;
  }
  
  .imgpaddingvisa {
    padding: 10px 0px;
    border-radius: 0.6rem;
    width: 100%;
  }
  .cardborder{
    border: 1px dashed #2e5fa345;
    box-shadow: none !important;
    background: #fefbee;
    border-radius: 0.5rem;
  }
  .cardTop {
    padding: 0px 20px;
    background-color: #fff;
  }
  .cardheading {
    color: #386a9a;
    font-family: 12px;
    color: rgb(56, 106, 154);
  }
  .cardlabel {
    color: #9c9c9c;
    font-family: "Montserrat";
    font-size: 12px;
  }
  .expirydetails {
    position: absolute;
    right: 70px;
    margin-left: -10px;
    margin-top: -77px;
  }
  .button {
    color: #fff;
    background-color: #2461a9;
    border: 0px;
    border-radius: 5px;
    padding: 8px 30px 8px 30px;
    margin-top: 25px;
  }
  .customContnair{
    padding: 0;
  }
  .headingcolorvalue {
    color: #f7bd17;
    font-size: 22px;
    font-weight: bold;
  }
  .flexDebit {
    display: flex;
    padding-top: 42px;
  }
  .flexDebit1{
    padding-top: 42px;
  }
  .alertTitleStyle{
    color: red;
    margin-top: 20px;
  }
  .cardNumStyle {
    border: 1px solid #DADADA;
    padding: 10px 1rem 10px 1rem;
    border-radius: 5px;
    min-width: 5rem;
    background: #fff;
  }
  .cardNumStyle1{
    border: 1px solid #DADADA;
    padding: 3px 0 3px 20px;
    border-radius: 5px;
  }
  .cardNameStyle::placeholder{
    font-size: 15px;
  }
  .cardSec {
    margin-top: 20px;
  }
  
  .labelStyle {
    font-weight: 600;
    margin: 10px 0 0 5px;
  }
  
  .cardNameStyle {
    border: none;
    font-size: 20px;
    width: 90%;
  }
  
  .cardNameStyle:focus {
    outline: none;
  }
  @media screen and (min-width: 200px) and (max-width: 900px) {
    .flexDebit{
      display: block;
    }
    .imagePaymentMethod{
      padding-bottom: 23px;
    }
    .paymentFirst{
      display: flex;
      width: auto;
      margin-bottom: 33px;
    }
    .imgpadding{
      margin-top: 0px;
      margin-right: 3px;
      padding:0px;
    }
    .labelStyle{
      font-size: 13px;
    }
    .paymentMethod{
      margin: 10px 0px;
    }
    .paymentMethodheadding{
      font-size: 24px;
    }
    .custmCard{
      padding: 15px 0px 15px 0px;
    }
    .imagePaymentMob{
      width: 100%;
    }
  }
  @media screen and (min-width: 901px) and (max-width: 1200px) {
  .paymentFirst{
    width: auto;
    margin-bottom: 33px;
  }
  }
  .loaderStyle {
    position: absolute;
    top: 50px;
    left: 55%;
  }
  
  .successModal {
    background-color: #2463AF;
    text-align: center;
  }
  
  .successModal img {
    width: 100%;
  }
  
  .successModal p {
    color: white;
  }