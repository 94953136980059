
.invoice-packages{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.extra-features{
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin: 1rem 5px;
}

.order-extras-details{
    display: flex;
    justify-content: space-between;
}

.invoice-total-amount{
    font-size: 1.1rem;
    font-weight: 700;
    margin: 0 5px;
    color: var(--primary-color);
    display: flex;
    justify-content: space-between;
}