@import url('../../App.css');
   
.dashboard-container{
   display: flex;
   flex-direction: column;
   margin-bottom: 4rem;
}

.yellow-horizhontal-line{
    height: 2rem;
    width: 100%;
    background-color: var(--secondary-color);
    border-radius: 7px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.packages-list-and-package-details{
    display: flex;
    gap: 1rem;
    min-height: 30rem;
    max-height: auto;
    margin-bottom: 4rem;
}

.packages-table{
    flex: 1;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
}

.primary-separation-button{
    margin-top: 10px;
    background: var(--primary-color) !important;
    margin-bottom: 5px;
    padding: 10px !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.package-details{
    flex: 0.6;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
}

.dashboard-table{
    display: flex;
    flex-direction: column;
}

.table-headings{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.table-heading{
    flex: 1;
}

.blue-button{
    background-color: var(--primary-color);
    color: var(--white-color);
    border-radius: 7px;
    padding: 4px 10px;
}

.border-blue-button{
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    padding: 4px 10px;
}

.table-content{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 25px 0;
}

.strip-table-content-img{
    padding: 0;
}

.red-button{
    background-color: var(--red-color);
    color: var(--white-color);
    border-radius: 17px;
    padding: 3px 5px;
    font-size: 0.7rem;
    font-weight: bold;
}

/* package-content-card */

.package-content-container{
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.package-name-img{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.package-name-number{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.gray-text{
    color: var(--gray-text-color);
}

.package-name{
    color: var(--primary-color);
    font-weight: 700;
    font-size: 20px;
}

.package-img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.package-dimensions{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
}
.package-content-card{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 700;  
}

.package-content-card-heading{
    font-weight: 700;
    color: var(dark-text-color);
}

.package-imgs{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.package-content-package-images{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 10px;
}

.package-detail-package-img{
    width: 5rem;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    padding: 5px;
}

.request-photos-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    gap: 2rem;
    margin: 1rem 0;
}

.two-buttons{
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
}

.combined-and-close-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    padding: 4px 10px;
}

.border-button{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
}

.forward-package-button{
    display: flex;
    color: var(--white-color);
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    border-radius: 7px;
    padding: 10px 0;
}

.forward-package-button-text{
    font-weight: 600;
}

.no-packages-container{
    margin: 2rem 0;
}

.big-gray-bold-text{
    font-size: 2.3rem;
    font-weight: 600;
    color: var(--border-color);
    margin-top: 1rem;
}

.text-border-color{
    color: var(--border-color);
}

/* multiple packages details */

.multiple-packages-container{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: space-between;
}

.packages-to-combined-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 28rem;
    overflow-y: scroll;
    margin-bottom: 1rem;
}

.combined-package-detail-container{
    border: 1px solid var(--border-color);
    padding: 1rem;
    border-radius: 7px;
}

.combined-package-button{
    display: flex;
    color: var(--white-color);
    justify-content: center;
    align-items: center;
    background: var(--primary-color);
    border-radius: 7px;
    padding: 10px 0;
}

.combined-package-details-buttons{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}


/* consolidation-content-card */

.consolidation-package-card-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.secondary-bold-big-text{
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--secondary-color);
    margin: 10px 0;
}

.primary-bold-text{
    font-weight: 700;
    color: var(--primary-color);
    margin: 10px 0;
}

.phone-icon-text{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
}

.consolidated-packages{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.responsive-dashboard-container{
    display: none;
}

.table-contents{
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.mobile-dashboard-container .table-heading{
    font-size: 1rem;
}

.mobile-package-detail-card{
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 8px;
}

.selected-mobile-package-detail-card{
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 8px;
    background-color: var(--lite-secondary-color);
}

@media screen and (max-width:1200px) {
    .wait24hours{
        width: 80%;
    }

    .package-status-img{
        width: 3rem;
    }

    .gray-bold--text{
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 1356px) and (min-width: 1200px) {
  .table-content {
    font-size: 0.8rem;
  }

  .table-content img{
    width: 2.5rem;
  }

  .table-headings .table-heading{
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 992px) {

    .mobile-package-detail-card{
        padding: 2rem;
    }

    .selected-mobile-package-detail-card{
        padding: 2rem;
    }

    .table-contents {
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

    .yellow-horizhontal-line{
        font-size: 0.7rem;
        height: auto;
        padding: 4px 2px;
    }

   .packages-list-and-package-details{
        flex-direction: column-reverse;
   }

   .dashboard-empty-packages-img{
        width: 100%;
   }

   .responsive-dashboard-container{
        display: flex;
   }

   .table-heading{
        font-size: 0.4rem;
   }

   .packages-table{
        padding: 10px;
   }

   .flex08{
        flex: 0.7!important;
   }

   .table-contents{
        width: 100%;
        padding: 0 !important;
    }

    .package-content-container {
        padding: 1rem;
    }

    .package-name-number {
        font-size: 0.7rem !important;
    }

    .package-status-img{
        width: 40%;
    }

    .gray-bold--text, .package-content-card-heading, .package-dimension, .package-content-package-images, .request-photo-button-text, .button-text, .forward-package-button-text{
        font-size: 1rem;
    }

    .request-photos-button {
        margin: 0 !important;
      }
}

@media screen and (max-width: 576px) {
    .mobile-package-detail-card {
        padding: 1rem;
    }

    .gray-bold--text, .package-content-card-heading, .package-dimension, .package-content-package-images, .request-photo-button-text, .button-text, .forward-package-button-text {
        font-size: 0.9rem;
    }

    .selected-mobile-package-detail-card {
        padding: 1rem;
    }
}

