@import url('../../../App.css');

.payment-info-billing-address-container{
    padding: 1rem 1rem 0 1rem;
    line-height: 1.7;
}

.two-forms{
    display: flex;
    gap: 1rem;
}

.card-info-form{
    border-right: 1px solid var(--border-color);
    padding-right: 1rem;
}

.checkbox-buttons{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.payment-img-text{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.secondary-bold-medium-text{
    color: var(--secondary-color);
    font-weight: 600;
    font-size: 1.5rem;
}

.payment-info-billing-address-container .form-label{
    font-weight: 600;
    margin: 10px 0 0 5px;
}

.payment-options{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 2rem;
}
.paypal-method.payment-option{
    border-color: transparent !important;
}

.payment-option{
    border: 1px solid var(--border-color);
    height: 11rem;
    width: 18rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    position: relative;
}

.selected-payment-option{
    border: 3px solid var(--secondary-color);
    height: 11rem;
    width: 18rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 7px;
    position: relative;
}

.selected-payment-option .correct-icon-bg{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: -10px;
    background: var(--secondary-color);
    padding: 3px;
    border-radius: 50%;
}

.payment-selected-icon{
    color: var(--white-color);
}

.start-mark{
    color: red
}

@media screen and (max-width: 1440px) {
    .payment-option {
        border: 3px solid var(--secondary-color);
        height: 11rem;
        width: 17rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-radius: 7px;
        position: relative;
    }

    .selected-payment-option {
        border: 3px solid var(--secondary-color);
        height: 11rem;
        width: 17rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-radius: 7px;
        position: relative;
    }
}

@media screen and (max-width: 992px) {
    .two-forms {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}