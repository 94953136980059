@import url('../../../App.css');

.profile-address-container{
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem !important;
    position: relative;
    width: 22rem;
}

.selected-profile-address-container{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1.5rem 2rem !important;
    background: var(--primary-color) !important;
    border-radius: 7px;
    width: 22rem;
}

.address-default-button{
    position: absolute;
    bottom: 5%;
    right: 5%;
    font-size: .9rem;
    color: var(--border-color);
    font-weight: 500;
}

.selected-profile-address-container .address-default-button{
    color: var(--secondary-color);
}

.selected-profile-address-container .address-fname-lname{
    color: var(--secondary-color);
}

.selected-profile-address-container .main-address{
    color: var(--white-color);
    font-weight: 500;
}

.selected-profile-address-container{
    color: var(--white-color);
}

.address-name-edit-delete-buttons{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.address-fname-lname{
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--primary-color);
    margin: 0;
}

.address-edit-delete-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.main-address{
    text-align: initial;
    margin: 5px 0;
}

.address-card-icon{
    width: 1.2rem;
    color: var(--primary-color);
}

@media screen and (max-width: 1830px) {
    .profile-address-container, .selected-profile-address-container{
        width: 21rem;
    }
}

@media screen and (max-width: 1760px) {
    .profile-address-container, .selected-profile-address-container{
        width: 20rem;
    }
}

@media screen and (max-width: 1680px) {
    .profile-address-container, .selected-profile-address-container{
        width: 19rem;
    }
}

@media screen and (max-width: 1605px) {
    .profile-address-container, .selected-profile-address-container{
        width: 18rem;
    }
}

@media screen and (max-width: 1536px) {

    .address-fname-lname{
        font-size: 1.3rem;
    }

    .main-address{
        margin: 6px 0 6px 0;
    }

    .profile-address-container, .selected-profile-address-container{
        width: 17rem;
    }

}

@media screen and (max-width: 1490px) {
    .profile-address-container, .selected-profile-address-container{
        width: 15rem;
        padding: 1rem !important;
    }

    .selected-profile-address-container .main-address , .profile-address-container .main-address{
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 1335px) {
    .profile-address-container, .selected-profile-address-container{
        width: 14rem;
        padding: 1rem 12px !important;
    }

    .address-edit-delete-buttons {
        gap: 5px;
    }

    .address-fname-lname {
        font-size: 1.1rem;
      }
}

@media screen and (max-width: 1258px) {
    .profile-address-container, .selected-profile-address-container{
        width: 13rem;
        padding: 1rem 10px !important;
    }

    .address-fname-lname {
        font-size: 1rem;
      }
}

@media screen and (max-width: 1181px) {
  .selected-profile-address-container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1.5rem 2rem !important;
    background: var(--primary-color) !important;
    border-radius: 7px;
    width: 100%;
  }

  .profile-address-container{
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem !important;
    position: relative;
    width: 100%;
}

    .address-edit-delete-buttons {
        gap: 1rem;
    }
}

@media screen and (max-width: 1181px) {
    .profile-address-container, .selected-profile-address-container{
        width: 18rem;
        padding: 1rem 10px !important;
    }
}

@media screen and (max-width: 991px) {
    .profile-address-container, .selected-profile-address-container{
        min-width: 18rem;
        padding: 1rem 10px !important;
    }

   
}