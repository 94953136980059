 /* .right-side-part {
    background-color: #F6F6F6;
} */
.ticket-w-60{
    width: 60%;
}
.ticket-w-100{
    width: 60%;
}
.d-t-flex{
    display: flex;
    align-items: center;
}
.d-t-flex h2,.d-t-flex div{
    margin-right: 15px;
}
.cus-b-btn-w-c{
    background-color: #D3D3D3;
    color: #000;
    padding: 15px 30px;
    border-radius: 8px;
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
    box-shadow: 1px 1px 8px 0px #00000021;
}
.cus-b-btn-w-r{
    background-color: #FF7F7F;
    color: #000;
    padding: 15px 30px;
    border-radius: 8px;
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
    box-shadow: 1px 1px 8px 0px #00000021;
}
.open-status{
    color: #000000;
    background-color:#90EE90;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 30px;
    border-radius: 50px;
}
.inprogress-status{
    color: #000000;
    background-color: #FFD580;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 30px;
    border-radius: 50px;
}
.closed-status{
    color: #000000;
    background-color: #D3D3D3;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 30px;
    border-radius: 50px;
}
.reopen-status{
    color: #000000;
    background-color: #FF7F7F;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 30px;
    border-radius: 50px;
}
.div-ticket-header{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
     gap: 20px;
}
.div-ticket-header h2{
    color: #5E5E5E;
    font-weight: 700;
    font-size: 22px;
}
.left-arrow{
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    color: #2461A9;
}
.cus-b-btn{
    background-color: #2461A9;
    color: #fff;
    padding:15px 30px;
    border-radius: 8px;
    font-weight: 500;
    text-transform: capitalize;
}
.cus-g-btn-c{
    background-color: #D3D3D3;
    color: #000000;
    padding:15px 30px;
    border-radius: 8px;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 50px;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    display: block;
    left: 15px;
    right: 15px;
}
.cus-g-btn-re{
    background-color: #FF7F7F;
    color: #000000;
    padding:15px 30px;
    border-radius: 8px;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 50px;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    display: block;
    left: 15px;
    right: 15px;
}
.ticket-details{
    width: 100%;
    /* display: flex; */
}
.td-div1{
    /* width: 35%; */
    background-color: #fff;
    padding: 20px 25px;
    margin-top: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px #00000030;
    margin-right: 10px;
    position: relative;
}
.message-div{
    /* width: 65%; */
    background-color: #fff;
    padding: 20px 25px;
    margin-top: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px #00000030;
}
.m-d-flex{
    display: flex;
    /* justify-content: space-between; */
    margin: 15px;
    text-align: left;
}
.m-d-flex div:first-child{
    width: 15%;
    margin-right: 25px;
}
.m-d-flex div:second-child{
    font-weight: 700;
}
.m-d-flex div:last-child{
    text-align: right;
}
.message-right{
    text-align: right;
    width: 50%;
    margin-left: auto;
    margin-right: 0;
    padding: 10px;
}
.message-left{
    text-align: left;
    width: 50%;
    padding: 10px;
}
.message-left h2{
    color: #000!important;
}
.td-div2 {
    max-height: 500px;
    min-height: 500px;
    overflow-y: scroll;
}
.td-div2 img{
    width: 100px;
}
.td-div2 h2{
    color: #000000;
    font-weight: 700;
    font-size:16px;
}
.td-div2 p{
    font-size: 14px;
    font-weight: 500;
}
.td-div2 h6{
    color: #B9B9B9;
    font-weight: 400;
    font-size: 13px;
    text-align: right;
}

.hide {
    display: none;
  }
  
  .btn {
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #ddd;
    box-shadow: 2px 2px 10px #eee;
    border-radius: 4px;
  }
  
  .btn-large {
    padding: 11px;
    font-size: 17.5px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  .message-send{
    display: flex;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
  }
  .btn-blue,.btn-blue:hover{
    background-color: #2463AF;
    color: #fff;
  }
  .send-icon{
    transform: rotate(-45deg);
  }
  .attach-icon{
    transform: rotate(45deg);
    color: #3A8AE8;
  }
  .message-images{
    width: 5rem;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    /* padding: 5px; */
    margin-right: 5px;
  }
  @media(max-width:767px){
    .message-div,.td-div1{
        width: 100%;
    }
    .ticket-details{
        display: block;
    }
    .message-right,.message-left{
        width: 90%;
    }
    .cus-g-btn{
        position: unset;
        margin-top: 20px;
    }
    .cus-g-btn-re {
        position: unset;
        margin-top: 20px;
    }
    .ticket-w-100{
        width: 100%;
    }
  } 