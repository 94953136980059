@import url('../../App.css');

.header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-heading{
    text-align: initial;
}
.profile-name{
    width: max-content;
}
.wallet-hover{
    display: none;
}
.wallet-batch:hover .wallet-hover{
    display: block;
    position: absolute;
    z-index: 1000;
    width: auto;
    border-radius: 7px;
    left: 0;
    top: 15px;
}
.wallet-icon {
    position: absolute;
    bottom: -10px;
}
.header-heading-text{
    font-size: 1.2rem;
    font-weight: 600;
}

.heading-span{
    color: var(--secondary-color)
}

.notification-profile-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.notification-container{
    display: flex;
    cursor: pointer;
    width: max-content;
}

.bell-batch{
    position: relative;
}

.notification-hover-content{
    display: none;
}

.bell-batch:hover .notification-hover-content{
    display: flex;
    position: absolute;
    z-index: 1000;
    width: 32rem;
    background: var(--white-color);
    border-radius: 7px;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    right: 29%;
    left: -25rem;
    top: 8px;
}

.verify-email-text{
    margin-left: 1rem;
    font-weight: 500;
}

.bell-icon{
    position: absolute;
    bottom: -10px;
}
.new-notity{
    color: #ff0000;
}
.notifi-after{
    position: absolute;
    display: inline-block;
    top: -10px;
    right: -10px;
    background: #2463AF;
    font-size: 15px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 15px;
    color: #fff;
    padding: 2px;
    font-weight: 500;
}

.notification-batch{
    position: relative;
    bottom: 10px;
    left: 20px;
    background-color: var(--primary-color);
    color: var(--white-color);
    width: 1.3rem;
    height: 1.3rem;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.wallet-value{
    margin-left: 10px;
    color: var(--primary-color);
    font-weight: 600;
}
.profile-container{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    margin-left: 25px;
}

.header-profile-img{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}
.dollars-value{
    font-weight: 530;
    /* font-size: 15px; */
}
.points-value{
    font-weight: 530;
    padding-left: 8px;
}
.points-text{
    padding-right: 10px;
    padding-left: 1px;   
}

.header-points-img{
    width: 2rem;
    height: 2rem;
}
.header-dollar-img{
    padding-left: 8px;
    padding-right: 8px;
}

.border-points{
    border-radius: 100px;
    border: 1px solid #C5C5C5;
    width: 291px;
    height: 50px;
    position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wallet-border{
    border-radius: 100px;
    border: 1px solid #C5C5C5;
    padding: 10px 15px;
    position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-hover-content{
    display: none;
}

.border-dollar{
    border-radius: 100px;
    border: 1px solid #C5C5C5;
    width: 195px;
    height: 50px;
    position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.eraned-points{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;padding-top: 5px;
}
.wallet-bader{
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-container:hover .profile-hover-content{
    display: flex;
    gap: 5px;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 5%;
    background: var(--white-color);
    box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    z-index: 6;
    padding: 1rem 10px;
    width: 12rem;
    border-radius: 7px;
    transition: .2s all ease-in-out;
}

.hover-button{
    cursor: pointer;
    padding: 5px 1rem;
    border-radius: 7px;
}

.hover-button:hover{
    background: rgb(240, 240, 240);
}

@media screen and (max-width: 992px) {
    .header-heading-text {
        font-size: 1.5rem;
        font-weight: 600;
    }
    .header-dollar-img{
        padding-left: 8px;
        padding-right: 8px;
    }

    .border-points{
        border-radius: 100px;
        border: 1px solid #C5C5C5;
        width: 291px;
        height: 50px;
        position: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .wallet-border{
        border-radius: 100px;
        border: 1px solid #C5C5C5;
        padding: 10px 15px;
        position: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .dollars-vlaue{
        font-weight: 530;
    }
    .header-points-img{
        width: 2rem;
        height: 2rem;
    }
    .border-dollar{
        border-radius: 100px;
        border: 1px solid #C5C5C5;
        width: 195px;
        height: 50px;
        position: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .eraned-points{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
    .wallet-bader{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .points-text{
        font-size: 18px;
        padding-right: 8px;
        padding-left: 4px;   
    }
    .dollar-text{
        font-size: 18px;
    }
    .dollars-value{
        font-weight: 530;
    }
    .points-value{
        padding-left: 7px;
        font-weight: 530;
    }
    .heading-text{
        font-size: 1rem;
    }

    .header-profile-img {
        width: 3rem;
        height: 3rem;
    }

    .profile-name{
        display: none;
    }

    .profile-container {
        gap: 0rem;
    }

    .bell-batch:hover .notification-hover-content {
        width: 17.4rem;
        right: 12%;
        left: -14rem;
        top: 8px;
      }
}
@media screen and (max-width: 767px) {
.header-heading-text{
    text-align: left;
}
}
@media screen and (max-width: 576px) {
.header-heading-text {
    font-size: 1.3rem;
    font-weight: 600;
}
.wallet-value{
    margin-left: 10px;
    color: var(--primary-color);
    font-weight: 600;
}
.points-text{
    font-size: 18px;
}
.header-dollar-img{
    padding-left: 8px;
    padding-right: 8px;
}
.points-vlaue{
    padding-left: 5px;
    padding-right: 8px;
    font-weight: 530;
}
.header-points-img{
    width: 2rem;
    height: 2rem;
}

.eraned-points{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: 5px;
}
.wallet-bader{
    display: flex;
    justify-content: center;
    align-items: center;
}
.border-dollar{
    border-radius: 100px;
    border: 1px solid #C5C5C5;
    width: 195px;
    height: 50px;
    position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.border-points{
    border-radius: 100px;
    border: 1px solid #C5C5C5;
    width: 300px;
    height: 50px;
    position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wallet-border{
    border-radius: 100px;
    border: 1px solid #C5C5C5;
    padding: 10px 15px;
    position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
}

@media screen and (max-width: 450px) {
    .header-heading-text {
        font-size: 1rem;
    }
    .wallet-value{
        margin-left: 10px;
        color: var(--primary-color);
        font-weight: 600;
        font-size: 15px;
    }
    .border-dollar{
        border-radius: 100px;
        border: 1px solid #C5C5C5;
        width: 195px;
        height: 40px;
        position: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .border-points{
        border-radius: 100px;
        border: 1px solid #C5C5C5;
        width: 300px;
        height: 40px;
        position: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .wallet-border{
        border-radius: 100px;
        border: 1px solid #C5C5C5;
        padding: 10px 15px;
        position: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .heading-text{
        font-size: 0.9rem;
    }
    .points-text{
        font-size: 3px;
    }
    .header-dollar-img{
        width: 30px;
        padding-left: 4px;
        padding-right: 4px;
    }
    .points-vlaue{
        padding-left: 5px;
        padding-right: 8px;
        font-weight: 530;
    }
    .header-points-img{
        width: 23px;
        height: 23px;
    }

    .eraned-points{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.6rem;
        padding-top: 5px;
    }
    .wallet-bader{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }
    .dollars-value{
        font-weight: 530;
        font-size: 15px;
    }
    .points-value{
        font-weight: 530;
        font-size: 15px;
    }
    .points-text{
        font-size: 13px;
        padding-right: 8px;
        padding-left: 4px;   
    }
    .dollar-text{
        font-size: 13px;
    }
    }