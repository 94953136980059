@import url('../../App.css');

.pagination-container{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    z-index: 10;
}

.pagination-btn{
    color: blue;
    border-bottom: 1px solid blue;
    cursor: pointer;
}

.not-allowed{
    cursor: not-allowed;
}

.btn-disabled{
    color: blue;
    pointer-events: none;
    opacity: 0.7;
    border-bottom: 1px solid blue;
}

.pagination-in-bottom{
    display: flex;
    position: fixed;
    bottom: 3%;
    left: 50%;
    gap: 1rem;
    margin-top: 3rem;
    z-index: 10;
    transform: translate(-50%);
}