@import url('../../App.css');

.review-order-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1rem;
    margin-bottom: 4rem;
}

.heading-details-container{
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: flex-end;
}

.order-details-container, .order-invoice-container{
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    box-shadow: 0px 4px 20px rgba(0,0,0,0.2);
    height: max-content;
}

.second-heading-details .shipping-carrier-heading{
    text-align: initial;
}

.review-order-details-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.review-order-detail-container{
    display: flex;
    gap: 1rem;
}

.review-heading-details{
    flex: 1;
}

.review-second-heading-details{
    flex: 0.6;
}

.order-total-amount{
    padding: 1rem;
    box-shadow: 0px 5px 20px rgba(0,0,0,0.2);
    border-radius: 7px;
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--primary-color);
    display: flex;
    justify-content: space-between;
}

.review-second-heading-details .two-buttons{
    margin: 0;
}

.split-payments-container{
    text-align: initial;
    margin-top: 1rem;
}

.split-select-box-container{
    background: #fff;
    border: 1.5px solid var(--primary-color);
    border-radius: 7px;
    padding: 5px 2rem;
}

.addressAndPaymentPage-container .review-second-heading-details{
    width: 100%;
    flex: 1;
}

@media screen and (max-width: 992px) {
    .review-order-detail-container {
        flex-direction: column;
    }

    .second-heading-details .shipping-carrier-heading {
        display: none;
    }

    .heading-details-container {
        display: block;
    }

    .checkout-calc-subheading{
        text-align: initial;
    }
}