@import url('../../App.css');

.shopping-page-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
}

.shopping-heading{
    font-size: 1.5rem;
    font-weight: 600;
}

.brands-imgs{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    margin: 1rem 0 1rem 1rem;
}

.brand-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 20px rgba(0,0,0,0.1);
    padding: 20px 30px 0 30px;
    border-radius: 4px;
    cursor: pointer;
}

.shopping-brand-img{
    width: 12rem;
    aspect-ratio: 4/2;
    object-fit: contain;
}

@media screen and (max-width: 1200px) {
    .shopping-brand-img {
        width: 8rem;
        aspect-ratio: 4/2;
        object-fit: contain;
    }
}

@media screen and (max-width: 992px) {
    .shopping-brand-img {
        width: 8rem;
        aspect-ratio: 4/2;
        object-fit: contain;
    }

    .brand-container {
        padding: 1rem;
    }
}