.activity-heading-content{
    text-align: left;
}
.seenmessage{
    background-color: #f5e7bf;
    padding: 10px;
}
.activity-image-heading-content{
    width: 100%!important;
}
.nac-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 10px;
    box-shadow: 1px 1px 7px 0px #61616130!important;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
}
.w-210{
    width: 210px;
}
.recent-activities-container1{
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    padding: 30px;
    border-radius: 7px;
    margin-top: 10px;
}
@media screen and (max-width:767px) {
    .nac-flex{
        display:block;
    }
    .w-210{
        width: auto;
    }
    
}