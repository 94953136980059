@import url('../../../App.css');

.questionnaire-form{
    display: flex;
    gap: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 7px;
    padding: 1rem;
    position: relative;
    padding-top: 4rem;
}

.reduce-form{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    background-color: var(--primary-color);
    color: var(--white-color);
    border-radius: 50%;
    font-size: 2rem;
    cursor: pointer;
    border: none;
    position: absolute;
    right: 2%;
    top: 10%;
}

.add-more-form{
    position: absolute;
    right: 7%;
    top: 10%;
}

@media screen and (max-width: 992px) {
    .questionnaire-form{
        gap: 5px;
        padding-top: 2rem;
        align-items: end;
    }

    .reduce-form{
        position: absolute;
        right: 5%;
        top: 10%;
    }
    
    .add-more-form{
        position: absolute;
        right: 10%;
        top: 10%;
    }
}