

.normal-input-error{
    color: red;
    font-weight: 400;
    font-size: 0.9rem;
    display: none;
}

.normal-input-container{
    flex: 1;
}

.normal-input-input{
    position: relative;
}

.red-text{
    color: red;
    font-weight: 400;
    margin-left: -3px;
}


.password-eye-icon{
    position: absolute;
    right: 5%;
    top: 1rem;
    font-size: 1.8rem;
}


.register-container .password-eye-icon {
    position: absolute;
    top: 0.7rem;
    right: 4%;
    font-size: 1.5rem;
}

input:invalid[focused='true'] ~ .normal-input-error{
    display: block;
}

input:invalid[focused='true']{
    border: 1px solid red;
}

.normal-input-error{
    /* font-size: 0.8rem; */
    letter-spacing: 0;
    line-height: 1.1;
}

@media screen and (max-width:1440px) {

    .password-eye-icon{
        font-size: 1.5rem;
    }

    .normal-input-error{
        font-size: 0.8rem;
        line-height: 1.1;
    }

}

@media screen and (max-width: 992px) {
    .password-eye-icon{
        top: 30% !important;
    }
}