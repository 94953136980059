@import url('../../App.css');

.accordian-container{
    display: flex;
    flex-direction: column;
}
.question-error{
    color: #ff0000;
    font-size: 14px;
    text-align: right;
}
.accordian-strip{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 14px;
}

.selected-accordian-strip{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 14px;
    background-color: #FFF2CB;
}

.table-content{
    flex: 1;
    text-align: center;
}

.table-package-img{
    margin: 0 auto;
}

.disposed-days-button{
    position: absolute;
    top: -19%;
    right: 4%;
}

.edit-pen-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.pen-edit-icon{
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--white-color);
    background-color: var(--primary-color);
}

.edit-pen{
    font-size: 1.3rem;
}

.strip-table-content-img{
    position: relative;
}

.doller-img{
    position: absolute;
    top: -10%;
    left: 60%;
}

@media screen and (max-width:1200px) {
    .table-package-img {
        margin: 0 auto;
        width: 2rem;
    }
    
    .table-content {
        gap: 1rem;
        padding: 25px 0;
        font-size: 0.8rem;
    }

    .edit-pen {
        font-size: 1.1rem;
    }
    
    .pen-edit-icon {
        height: 1.5rem;
        width: 1.5rem;
    }    
}

@media screen and (max-width: 992px) {
    .table-content{
        font-size: 0.5rem;
        padding: 10px !important;
    }

    .disposed-days-button{
        position: absolute;
        top: -15%;
        right: 4%;
    }

    .red-button{
        font-size: 0.4rem !important;
    }

    .pen-edit-icon {
        height: 1.2rem;
        width: 1.2rem;
    }

    .edit-pen {
        font-size: 0.6rem;
    }

    .table-package-img{
        width: 100%;
    }

    /* .accordian-strip {
        margin-bottom: 10px;
    }

    .selected-accordian-strip{
        margin-bottom: 10px;
    } */
}