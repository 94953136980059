@import url('../../App.css');

.address-payments-container{
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 1rem;
    margin-bottom: 4rem;
}

.profile-addresses-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.profile-payments-container{
    flex: 1;
    height: 100%;
}

.card{
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 7px;
    height: auto;
}

.addresses-container{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
}

.profile-card-heading-icon{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-heading-icon{
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
    text-align: initial;
}

.plus-icon-img{
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
}

/* card details  */

.payment-details{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
}

.payment-detail-container{
    display: flex;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    padding: 1rem 2rem;
    min-width: 31rem;
}

.selected-payment-detail-container{
    background: var(--primary-color);
    display: flex;
    align-items: flex-start;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    padding: 1rem 2rem;
    color: var(--white-color);
    min-width: 31rem;
}

.card-detail-section{
    flex: 1;
    border-right: 1px solid var(--border-color);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 3px;
    line-height: 2;
}

.card-address-section{
    flex: 1;
    padding-left: 2rem;
}

.card-name{
    font-weight: 600;
}

.input-cvv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.cvv-input{
    width: 5rem;
}

.selected-payment-detail-container .card-name{
    color: var(--secondary-color);
}

.selected-payment-detail-container .address-fname-lname{
    color: var(--secondary-color);
}

.card-provider-icon{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    padding-right: 1rem;
}

@media screen and (max-width: 1300px) {
    .payment-detail-container, .selected-payment-detail-container {
        display: flex;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
        padding: 1rem 1rem;
        min-width: auto;
    }

    .card-address-section {
        flex: 1 1;
        padding-left: 1rem;
    }

    .card-number{
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 992px) {
    .address-payments-container {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        gap: 1rem;
        flex-direction: column;
    }

    .selected-address-payments-container {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        gap: 1rem;
        flex-direction: column;
    }

    .payment-detail-container, .selected-payment-detail-container  {
        position: relative;
        min-width: 18rem;
        flex-direction: column;
    }

    .selected-payment-detail-container .card-detail-section {
        border-right: none !important;
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        width: 100%;
    }

    .card-address-section {
        flex: 1 1;
        padding-left: 0;
    }

    .card-detail-section {
        border-right: none !important;
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 1rem;
        padding-bottom: 1rem;
    }

    .delete-card-button{
        position: absolute;
        top: 5%;
        right: 5%;
    }

    .payment-details {
        flex-direction: row;
    }

    .addresses-container {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
        flex-wrap: nowrap;
        overflow: scroll;
      }

      .payment-details{
        display: flex;
        flex-wrap: nowrap;
        gap: 1rem;
        margin: 1rem 0;
        overflow: scroll;
    }
}

@media screen and (max-width: 500px) {
    .payment-detail-container, .selected-payment-detail-container  {
        width: 100%;
    }
}

