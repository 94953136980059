@import url('../../../App.css');

.personal-details-form{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.two-inputs{
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    width: 100%;
    margin: 5px 0 10px 0;
}

.form-label-input-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
}

.personal-details-form-submit-button{
    color: #fff;
    background-color: var(--secondary-color);
    padding: 5px 1rem;
    width: fit-content;
    border-radius: 7px;
}

.feedback-text{
    text-align: initial;
}

@media screen and (max-width: 992px) {
    .personal-details-form .two-inputs{
        flex-direction: column;
    }

    .personal-details-form .form-label-input-container {
        width: 100%;
    }

    .personal-details-form .form-label, .form-input {
        font-size: 1rem;
    }
}