@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

*{
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

:root{
  --primary-color: #235EA3;
  --primary-dark-color: #184880;
  --secondary-color: #F7BD16;
  --white-color: #fff;
  --white-hover-color:#f7f7f7;
  --black-color:#000;
  --border-color: #C4C4C4;
  --red-color: #FF0000;
  --gray-text-color: #838383;
  --text-dark-color: #3F3F3F;
  --lite-secondary-color: #FFEFC2;
}

.right-side-part{
  position: relative;
}

.active-right-side-part{
  position: relative;
}

.red-color{
  color: var(--red-color);
}

.blur-img{
  opacity: 0.2;
  cursor: pointer;
}

.flex05{
  flex: 0.5 !important;
}

.flex06{
  flex: 0.6 !important;
}

.flex08{
  flex: 0.8 !important;
}

.App {
  text-align: center;
}

.authenticated-routes{
  display: flex;
}


.active-right-side-part{
  padding: 1rem;
  width: 93%;
}

.right-side-part{
  padding: 1rem;
  width: 85%;
}

.cursor{
  cursor: pointer;
}

.display-none{
  display: none;
}

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-text{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

@media screen and (max-width:1536px) {
  .right-side-part{
    padding: 1rem;
    width: 83%;
  }
}


@media screen and (max-width:992px) {
  .authenticated-routes{
    display: flex;
    flex-direction: column;
  }

  .right-side-part{
    width: 100%;
  }

  .active-right-side-part{
    display: none;
    padding: 1rem;
    width: 100%;
  }
}
