@import url('../../App.css');

.carriers-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.carriers-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
}

.shipping-carrier-heading{
    color: var(--text-dark-color);
    font-weight: 700;
    font-size: 1.1rem;
}

.sort-icon-text{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.sort-buttons-icon{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    width: 100%;
}

.sort-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.sort-button{
    padding: 10px 1rem;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
}

.shipping-carriers-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.carrier-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    padding: 10px 2rem;
    min-height: 5rem;
    width: 100%;
}

.selected-carrier-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    background: var(--primary-color);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    padding: 10px 2rem;
    width: 100%;
    color: var(--white-color);
}

.bold-gray-text{
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
}

.carrier-amount{
    font-weight: 700;
}

.carrier-name{
    font-weight: 600;
}

.carriers-save-button{
    background: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 7px ;
    border-radius: 7px;
    color: var(--white-color);
    font-weight: 500;
}

.oops-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin:  3rem auto;
}

.spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:  10rem auto;
}

.disabled{
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
}

@media screen and (max-width: 1200px) {
    .oops-img{
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .shipping-right-side-content {
        margin: 0 !important;
      }

    .oops-container{
        margin: 0;
        width: 98%;
    }

    .shipping-carriers-container {
        width: 100%;
    }
}