@import url('../../App.css');
.payment{ 
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    position: relative;
}

.payment-card {
    width: 650px;
    /*height: 770px;*/
    padding: 30px;
    border-radius: 30px;
    background: #FFF;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.07);
    margin-top: 40px!important;
    margin: 0px auto;
}

.payment-img{
    margin-top: 30px;
    width: 320px;
}

.payment-thank{
    margin-top: 10px;
    color: #F7BD16;
    text-align: center;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
}

.payment-msg{
    margin-top: 10px;
    color: #2463AF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700; 
}

.payment-paid{
    margin-top: 10px;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.payment-amount{
    color: #000;
    text-align: center;
    font-size: 45px;
    font-style: normal;
    font-weight: 700; 
}

.payment-package{
    margin-top: 20px;
    color: #2463AF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}
.payment-text{
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.payment-dashboard{
    margin-top: 15px;
    padding: 10px 30px;
    border-radius: 51.5px;
    background: #18477D;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    display: inline-block;
}

.payment-button{
    cursor: pointer;
}

@media (max-width:768px){
    .payment-card {
        width: 100%;
        height: 100%;
        border-radius: 13px;
        background: #FFF;
        box-shadow: 0px 4px 22.9px 0px rgba(0, 0, 0, 0.07);
        left: 10px;
        right: 10px;
    }
    
    .payment-thank{
        margin-top: 10px;
        color: #F7BD16;
        text-align: center;
        font-size: 25px;
    }
    
    .payment-msg{
        margin-top: 10px;
        color: #2463AF;
        text-align: center;
        font-size: 15px; 
    }
    
    .payment-paid{
        margin-top: 10px;
        color: #000;
        font-size: 14px;
    }
    
    .payment-amount{
        color: #000;
        text-align: center;
        font-size: 25px; 
    }
    
    .payment-package{
        margin-top: 10px;
        color: #2463AF;
        font-size: 14px;
    }
    .payment-text{
        color: #000;
        text-align: center;
        font-size: 14px;
    }
    
    .payment-dashboard{
        color: #FFF;
        text-align: center;
        font-size: 15px;
    }

    .payment-img{
        padding: 0 40px;
    }
}
