@import url('../../../App.css');

.review-order-details-accordian-header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 1rem;
}

.two-details-container{
    display: flex;
    justify-content: space-between;
    text-align: initial;
    margin: 1rem;
}

.one-detail-container{
    margin-top: 1rem;
}

.order-secondary-bold-text{
    color: var(--secondary-color);
    font-weight: 600;
    font-size: 1.1rem;
    margin: 10px 0;
}

.order-primary-bold-text{
    color: var(--primary-color);
    font-weight: 600;
    font-size: 1rem;
}

.mt1{
    margin-top: 1rem;
}

.bold-big-text{
    font-weight: 600;
}