@import url('../../App.css');

.shipping-calc-page-container{
    display: flex;
}

.shipping-left-side-content{
    flex: 1;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 2px solid var(--border-color);
    padding-right: 2rem;
    margin-left: -1rem;
    height: max-content;
}

.shipping-right-side-content{
    flex: 0.8;
    display: flex;
    flex-direction: column;
    text-align: initial;
    margin-left: 1rem;
    width: 100%;
}

.shipping-left-top-content , .shipping-left-bottom-content{
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
}

.shipping-card{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    padding: 1.5rem 1rem ;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px 0;
    position: relative;
    margin: 2rem 0 0 1rem;
    height: 15rem;
    width: 25rem;
}

.shipping-card-heading{
    color: var(--text-dark-color);
    font-weight: 700;
    font-size: 1.1rem;
    position: absolute;
    top: -2rem;
    left: 0;
}

/* ship from card */

.ship-from-card{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2.5rem 1rem;
    gap: 1rem;
}

.ship-from-card-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.ship-from-address{
    text-align: initial;
}

.primary-bold-big-text{
    font-weight: 700;
    color: var(--primary-color);
    font-size: 1.5rem;
}

.primary-text{
    color: var(--primary-color);
    font-weight: 500;
}

.secondary-bold-text{
    font-weight: 700;
    margin: 0;
    color: var(--secondary-color);
}

/* ship to card */

.ship-to-card{
    flex: 1;
    max-height: 13rem;
    overflow-y: scroll;
    margin: 0 auto;
    width: 100%;
}

.ship-to-addresses{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.to-ship-address-card{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 7px;
    gap: 1rem;
    width: 100%;
}

.selected-to-ship-address-card{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 7px;
    gap: 1rem;
    background: var(--primary-color);
    color: var(--white-color);
    width: 100%;
}

.pen-icon{
    position: absolute;
    top: 10%;
    right: 5%;
}

.selected-to-ship-address-card .ship-to-address-name, .selected-to-ship-address-card .ship-to-address-address{
    color: var(--white-color);
}

.ship-to-address-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: initial;
}

.m0{
    margin: 0;
}

.my-packages{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.my-packages-card-container{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-height: 11rem;
    overflow-y: scroll;
    width: 100%;
}

.my-packages-card{
    flex: 1;
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 7px;
    width: 100%;
    min-height: 6rem;
}

.selected-my-packages-card{
    flex: 1;
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    border-radius: 7px;
    background: var(--primary-color);
    color: var(--white-color);
    width: 100%;
    min-height: 6rem;
}

.selected-my-packages-card .my-packages-trackId{
    color: var(--secondary-color);
}

.package-dimensions-container{
    display: flex;
    flex-direction: column;
    line-height: 2.5;
    width: 100%;
}

.package-dimension-input{
    border: 1px solid var(--border-color);
    border-radius: 7px;
    padding: 0px 4px;
    width: 100%;
}

.package-dimension-label{
    display: flex;
    flex-direction: column;
    text-align: initial;
    width: 100%;
}

.length-width-height{
    display: flex;
}

.three-inputs{
    display: flex;
    gap: 1rem;
    align-items: flex-end;
}

.label-input{
    flex: 1;
}

.three-inputs .package-dimension-input{
    max-width: 7rem;
    min-width: 100%;
}

.shipping-cost-button{
    width: fit-content;
    background: var(--primary-color);
    color: var(--white-color);
    padding: 6px 1rem;
    border-radius: 10px;
    margin-top: 1rem;
}

.highlited-gray-text{
    color: var(--text-dark-color);
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.estimate-cost-button{
    margin-top: 1rem;
    width: max-content;
    padding: 8px 2rem;
    border-radius: 7px;
    font-weight: 500;
    cursor: pointer;
}

.weight-lb-kg{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-end;
    margin-bottom: 4px;
}

.switch-fields{
    border: 1px solid var(--primary-color);
    border-radius: 30px;
    height: fit-content;
    width: fit-content;
    display: flex;
    padding: 1px;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.unit{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}

.selected-unit{
    background: var(--primary-color);
    color: var(--white-color);
    font-weight: 500;
}

.switch-fields input[type='radio']{
    visibility: hidden;
}

.country-flag{
    border-radius: 50%;
    height: 7rem;
    width: 7rem;
    object-fit: cover;
}

.ship-to-addresses .country-flag{
    border-radius: 50%;
    height: 4.5rem;
    width: 4.5rem;
    object-fit: cover;
}

@media screen and (max-width: 1560px) {
    .shipping-right-side-content{
        flex: 3 !important;
    }
}

@media screen and (max-width: 1440px) {

    .three-inputs{
        /* width: 14.5rem; */
        gap: 10px;
    }

    /* .three-inputs .package-dimension-input {
        width: 3.2rem;
    } */

    .shipping-card{
        min-width: 16rem;
        min-height: 14rem;
    }

    .shipping-left-side-content {
        padding-right: 1rem;
    }

    .primary-bold-big-text {
        font-size: 1.3rem;
    }

    .country-flag {
        height: 6rem;
        width: 6rem;
    }
}

@media only screen and (max-width: 1356px) and (min-width: 1200px) {
    .country-flag {
        height: 4.5rem;
        width: 4.5rem;
    }

    .ship-to-addresses .country-flag {
        height: 4rem;
        width: 4rem;
      }

      .primary-text {
        font-size: 0.9rem;
      }

      .secondary-bold-text {
        font-size: 0.9rem;
      }
}

@media screen and (max-width: 1260px) {
    .ship-from-card {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem 10px;
        gap: 1rem;
    }

    .shipping-calc-page-container {
        display: flex;
        flex-direction: column;
    }

    .shipping-left-side-content {
        border-right: none;
    }

    .shipping-card {
        max-width: 22.5rem;
    }

    .carrier-container, .selected-carrier-container {
        width: 80%;
        padding: 15px 2rem;
    }



    .shipping-left-top-content, .shipping-left-bottom-content {
        gap: 0rem;
    }

    .carriers-container { 
        margin-bottom: 3rem;
      }
}

@media screen and (max-width: 992px) {
    .shipping-calc-page-container {
        display: flex;
        flex-direction: column;
    }

    .carrier-container, .selected-carrier-container {
        width: 100%;
      }

    /* .shipping-left-top-content, .shipping-left-bottom-content {
        flex-direction: column;
    } */

    .shipping-card {
        width: 100%;
        max-width: 25rem;
    }

    .three-inputs {
        width: 100%;
        gap: 1rem;
    }

    .primary-bold-big-text {
        font-size: 1.6rem;
    }

    .secondary-bold-text {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 768px) {

    .country-flag {
        height: 6rem;
        width: 6rem;
    }

    .primary-bold-big-text {
        font-size: 1.4rem;
    }

    .secondary-bold-text {
        font-size: 1rem;
    }

    .ship-from-address {
        text-align: initial;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 600px) {

    .country-flag {
        height: 7rem;
        width: 7rem;
    }

    .primary-bold-big-text {
        font-size: 1.6rem;
    }

    .secondary-bold-text {
        font-size: 1.1rem;
    }

    .ship-from-address {
        text-align: initial;
        font-size: 1rem;
    }

    .shipping-left-top-content, .shipping-left-bottom-content {
        flex-direction: column;
    }


    .primary-bold-big-text {
        font-size: 1.5rem;
    }

    .country-flag {
        height: 7rem;
        width: 7rem;
    }

    .shipping-card {
        max-width: 100%;
        height: 20rem;
      }

      .ship-from-card {
        justify-content: center;
      }
}

@media screen and (max-width: 450px) {
    .cross-line {
        font-size: 11px;
        margin-top: 1rem;
    }

    .secondary-bold-text {
        font-size: 1rem;
    }
}
