
@import url('../../App.css');

.sidebar-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    background-color: var(--primary-color);
    color: var(--white-color);
    gap: 2rem;
    width: 15%;
}

.small-sidebar-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    background-color: var(--primary-color);
    color: var(--white-color);
    gap: 20rem;
    width: 7%;
}

.sidebar-upper-content{
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
}

.center-sidebar-upper-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
}

.logo{
    display: flex;
    justify-content:center;
    align-items: center;
    gap: 5px;
}

.verticle-logo{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    gap: 1rem;
}

.center-sidebar-lower-content{
    margin-bottom: 2rem;
}

.my-package-forwarder-logo{
    width: 11rem;
    object-fit: fill;
    aspect-ratio: 3/1;
}

.left-arrow-icon{
    width: 2.5rem;
    height: 2.5rem;
}

.nav-links{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    margin: 2rem 1rem 2rem 0;
}

.center-nav-links{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 2rem 0;
}

.mpf-small-logo{
    width: 100%;
}

.navlink{
    display: flex;
    justify-content:flex-start;
    align-items: center;
    color: var(--white-color);
    text-decoration: none;
    gap: 1rem;
    width: 100%;
}

.navlink:hover{
    color: var(--white-color);
}

.navlink-img{
    width: 2.5rem;
}

.active-link{
    display: flex;
    justify-content:flex-start;
    align-items: center;
    text-decoration: none;
    gap: 1rem;
    width: 100%;
}

.active-link .link-text{
    background: var(--secondary-color);
    color: var(--primary-color);
    text-align: initial;
    width: 100%;
    height: 3rem;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    border-right: 6px solid var(--white-color);
}

.quick-link-container{
    display: flex;
    flex-direction: column;
    margin: 2rem 0 0 0;
}

.link-heading{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.quick-link-img{
    width: 3rem;
}

.quick-link-text{
    font-size: 1.2rem;
}

.external-links{
    display: flex;
    flex-direction: column;
    text-align: initial;
    margin: 2rem 0 0 4rem;
    border-left: 1px solid var(--secondary-color);
    gap: 1rem;
    padding-left: 1rem;
}

.verticle-external-links{
    display: flex;
    flex-direction: column;
    text-align: initial;
    margin-bottom: 2rem;
}

.external-links a , .text-links a{
    color: var(--white-color);
    text-decoration: none;
}

.external-links a:hover , .text-links a:hover{
    color: var(--white-color);
    text-decoration: none;
}

.social-media-links-container{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.social-media-links{
    display: flex;
    gap: 1rem;
}

.verticle-social-media-links{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.social-media-link-img{
    width: 1.5rem;
}

.text-links{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 2rem 0;
}

@media screen and (max-width:1536px) {

    .sidebar-container{
        width: 17%;
    }

    .sidebar-upper-content{
        padding: 2rem 1.2rem;
    }

    .my-package-forwarder-logo{
        width: 10.5rem;
        height: 3.5rem;
    }

    .left-arrow-icon{
        width: 2.2rem;
        height: 2.2rem;
    }

    .navlink-img{
        width: 2.2rem;
    }

    .quick-link-img{
        width: 2.5rem;
    }

    .external-links{
        margin: 2rem 0 0 3rem;
    }
}

@media screen and (max-width:1440px) {

    .my-package-forwarder-logo{
        width: 10.2rem;
        height: 3.5rem;
    }

    .left-arrow-icon{
        width: 2rem;
        height: 2rem;
    }

    .navlink-img{
        width: 2rem;
    }

    .quick-link-img{
        width: 2rem;
    }

    .external-links{
        margin: 2rem 0 0 2rem;
    }

    .sidebar-upper-content{
        padding: 2rem 1.2rem;
    }

    .active-link .link-text{
        /* width: 9rem; */
        height: 2.5rem;
    }
}

@media screen and (max-width: 1366px) {
    .nav-links {
        margin: 2rem 0rem 2rem 0;
    }
}

@media only screen and (max-width: 1350px) and (min-width: 1000px){
    .active-link, .link-text{
        font-size: 0.8rem;
    }

    .link-text{
        font-size: 0.9rem;
    }

    .sidebar-upper-content {
        padding: 2rem 0.8rem;
    }
}

@media screen and (max-width:1200px) {

    .my-package-forwarder-logo {
        width: 8rem;
        height: 3rem;
    }

    .left-arrow-icon{
        width: 1.6rem;
        height: 1.6rem;
    }

    .navlink-img{
        width: 2rem;
    }

    .quick-link-img{
        width: 2.2rem;
    }

    .external-links{
        margin: 2rem 0 0 2rem;
    }

    .sidebar-upper-content{
        padding: 1rem;
    }

    .active-link, .link-text{
        font-size: 0.9rem;
    }

    .social-media-links {
        gap: 10px;
    }
}

@media screen and (max-width:992px) {

    .sidebar{
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; 
        overflow: scroll;
        background-color: rgb(0,0,0);
        transition: .6s all ease-in-out;
    }

    .sidebar-container{
        width: 40%;
    }

    .sidebar-content{
        height: 100%;
        width: 100%;
    }

    .displaynone{
        display: none;
    }

    .my-package-forwarder-logo{
        width: 12rem;
        height: 4rem;
    }

    .left-arrow-icon{
        width: 2.2rem;
        height: 2.2rem;
    }

    .navlink-img{
        width: 2.5rem;
    }

    .quick-link-img{
        width: 2.5rem;
    }

    .external-links{
        margin: 2rem 0 0 3rem;
    }

    .sidebar-upper-content{
        padding: 2rem 0 0 2rem;
    }
}

.mobile-side-bar{
    display: none;
}

/* .mobile-sidebar-container{
    display: none;
} */



@media screen and (max-width: 992px) {

    .sidebar-main-container{
        display: flex;
    }

    .small-sidebar-container{
        display: none;
    }

    .mobile-side-bar{
        display: flex;
        position: relative;
        background-color: var(--primary-color);
        min-height: 5rem;
        max-height: fit-content;
    }

    .mobile-my-package-forwarder-logo{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12rem;
    }

    .my-package-forwarder-logo{
        width: 12rem;
    }

    .social-media-links {
        gap: 1rem;
    }

    .responsive-toggle-button{
        margin-top: 10px;
        margin-left: 10px;
    }

    .responsive-button {
       position: absolute;
       top: 50%;
       left: 5%;
       transform: translate(-5%, -50%);
    }

    .sidebar-lower-content{
        margin-bottom: 2rem;
    }

    .sidebar-container {
        gap: 10rem;
    }

      .active-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        gap: 1rem;
        width: 100%;
    }

    .active-link .link-text {
        width: 50%;
    }

    .mobile-sidebar-container{
        width: 40%;
        /* height: 100vh; */
    }

    .sidebar-empty-space{
        width: 60%;
    }
 }

 @media screen and (max-width: 700px) {
    .mobile-my-package-forwarder-logo {
        width: 10rem;
        aspect-ratio: 3/1;
    }

    .mobile-sidebar-container {
        width: 60%;
        /* height: 100vh !important; */
    }

    .sidebar-empty-space{
        width: 40%;
    }

    .active-link .link-text {
        width: 60%;
    }

    .active-link, .link-text {
        font-size: 0.9rem;
    }
 }

@media screen and (max-width: 460px) {
    .mobile-sidebar-container{
        width: 70%;
    } 

    .sidebar-empty-space{
        width: 30%;
    }
}

@media screen and (max-width: 394px) {
    .mobile-sidebar-container{
        width: 85%;
    }

    .sidebar-empty-space{
        width: 15%;
    }
}