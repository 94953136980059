@import url('../../App.css');

.package-content-container .primary-button{
    background: var(--primary-color) !important;
    margin-bottom: 10px;
}
.payment-method-page-container hr{
    margin: 0!important;
}
.summary-items-container p{
    margin-bottom: 0;
    line-height: 16px;
}
.minus-amount p{
    font-size: 14px;
}

.small-button-text{
    font-size: 0.8rem;
    margin-top: -1rem;
    color: var(--primary-color);
}
.wallet-amount-input{
    padding: 8px 20px;
    border:2px solid #C4C4C4;
    border-radius: 5px;
}
.wallet-amount-message{
    color: #646464;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 14px;
}
.select-amo-heading{
    color: #F7BD16;
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
}
.wallet-amo-ul{
    list-style: none;
    padding: 0;
    text-align: center;
}
.wallet-amo-ul li{
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
    border: 1px solid #2463AF;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 40px;
    border-radius: 50px;
    color: #2463AF;
    cursor: pointer;
}
.hold-btn{
    background-color: #FFDEE0;
    color: #CD1E25;
    font-weight: 700;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
}
.blue-div{
    position: relative;
}
.blue-div:before{
    content: "";
    position: absolute;
    display: inline-block;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffffa6;
    left: 0;
}