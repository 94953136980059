.notification-container{
    width: 100%!important;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.unseen-notification{
    display: flex;
    gap: 1rem;
    cursor: pointer;
    padding: 1rem;
    align-items: center;
}

.unseen-notification:hover{
    background-color: #f1eded;
}

.notification-content{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    text-align: initial;
}


.notification-title span{
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 400;
}

.notification-title-time{
    display: flex;
    justify-content: space-between; 
    gap: 15px;
    align-items: center;
}
.noti-img{
    width: 50px;
}
.ntt-div{
    display: flex;
    gap: 15px;
}
.color-red{
    color: #f00;
}
.unseen-notification,.seen-notification{
    border-bottom: 1px solid #c4c4c4;
}
.notification-time{
    color: #000;
    font-weight: 400;
    font-size: 14px;
}

.notification-img{
    width: 4rem;
}

.notification-content{
    color: #838383;
}

.seen-notification{
    background-color: #f5e7bf;
    padding: 10px;
}

.seen-notification:hover{
    background-color: #f3d992;
}

.clear-notification-button{
    text-align: center;
    padding: 1rem 0;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.gray-text{
    color: rgb(201, 198, 198);
}



@media screen and (max-width:900px) {
    
    .notification-img{
        width: 3.5rem;
        height: 3.5rem
    }

    .unseen-notification{
        padding: 10px;
    }

    .notification-title{
        font-size: 1rem;
    }

    .notification-time{
        font-size: .9rem;
    }

    .notification-content{
        font-size: .9rem;
    }
}

@media screen and (max-width:992px) {
    .unseen-notification {
        padding: 5px;
    }

    .notification-title span,  .notification-time, .notification, .clear-notification-button{
        font-size: 0.8rem;
    }

    .notification-title-time {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding: 0 10px;
    }

    .clear-notification-button {
        padding: 8px 0;
    }

    .bell-batch:hover .notification-hover-content {
        width: fit-content !important;
    }
}