@import url('../../App.css');

.wallet-money-full-transaction{
    margin-top: 30px;
}
.wallet-filter{
    display: flex;
    gap: 1rem;
    margin-bottom: 15px;
    align-items: center;
}
.wallet-filter p{
    margin-bottom: 0;
}
.wallet-filter button{
    padding: 10px;
    background-color: #235ea3;
    border: 1px solid #235ea3;
    color: #fff;
}
.wallet-filter input{
    padding: 10px;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
}
@media(max-width:767px){
    .wallet-table-header{
        display: block;
    }
    .wallet-money-ava{
        padding: 15px;
    }
    .wallet-filter p{
        font-size: 14px;
    }
    .hide-xs{
        display: none;
    }
    .mobile-tran-flex{
        flex: 1;
        display: flex;
        gap: 2rem;
        justify-content: space-between;
    }
    .transaction-mobile{
        padding: 10px;
        margin-top: 15px;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }
    .transaction-mobile p{
        font-size: 15px;
        text-align: left;
        margin-bottom: 2px;
        font-weight: 700;
    }
    .mobile-tran-flex p:last-child{
        font-weight: 400;
        text-align: right;
    }
}
@media(min-width:767px) {
    .hide-sm{
        display: none;
    }
}