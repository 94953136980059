@import url('../../../App.css');

.payment-details{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
}

.payment-detail-container{
    display: flex;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    padding: 1rem 2rem;
}

.selected-payment-detail-container{
    background: var(--primary-color);
    display: flex;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    padding: 1rem 2rem;
    color: var(--white-color);
}

.selected-card-detail-section-com{
    min-width: 19rem;
    border-right: 1px solid var(--border-color);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 3px;
    position: relative;
    background: var(--primary-color);
    color: var(--white-color);
}

.card-detail-section-com{
    border-right: 1px solid var(--border-color);
    min-width: 19rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 3px;
    position: relative;
}

.card-provider-img{
    position: absolute;
    bottom: 10%;
    right: 7%;
    width: 3rem;
}

.card-address-section{
    flex: 1;
    padding-left: 2rem;
}

.card-name{
    font-weight: 600;
}

.input-cvv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.cvv-input{
    width: 5rem;
}

.selected-card-detail-section-com .card-name{
    color: var(--secondary-color);
}

.selected-card-detail-section-com .address-fname-lname{
    color: var(--secondary-color);
}

.selected-card-detail-section-com .card-provider-img{
    background: var(--white-color);
}

@media screen and (max-width: 992px) {
    .payment-detail-container {
        display: flex;
        border: 1px solid var(--primary-color);
        border-radius: 7px;
        padding: 1rem 2rem;
        flex-direction: column;
    }
}
