@import url('../../App.css');

.text-center{
    text-align: center;
}

.shipped-package-content-container{
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
}

.shipped-package-content-container .package-name-img{
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 1rem;
    gap: 2rem;
}

.shipped-package-content-container .package-name-number{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.gray-text{
    color: var(--gray-text-color);
}

.package-name{
    color: var(--primary-color);
    font-weight: 700;
    font-size: 20px;
    text-align: initial;
}

.package-img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    align-items: center;
}

.shipped-package-content-container .package-dimensions{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.5rem;
}

.package-dimension{
   text-align: initial;
}

.package-content-card-heading{
    font-weight: 700;
    color: var(dark-text-color);
}

.package-imgs{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.request-photos-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    gap: 2rem;
    margin: 1rem 0;
}

.two-buttons{
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
}

.border-button{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
}

.forward-package-button{
    display: flex;
    color: var(--white-color);
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    border-radius: 7px;
    padding: 10px 0;
}

.forward-package-button-text{
    font-weight: 600;
}

/* consolidation-content-card */

.consolidation-package-card-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.secondary-bold-big-text{
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--secondary-color);
    margin: 10px 0;
}

.primary-bold-text{
    font-weight: 700;
    color: var(--primary-color);
    margin: 10px 0;
}

.phone-icon-text{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0 auto;
}

/* package detail package table */

.single-package-details{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.package-table{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
}

.summry-heading-text{
    text-align: initial;
}

.package-table-headings{
    display: flex;
    margin: 5px 0;
}

.package-table-heading{
    flex: 1;
    color: var(--primary-color);
    text-align: initial;
}

.package-table-contents{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.package-table-content{
    display: flex;
    text-align: initial;
}

.package-table-data{
    flex: 1;
}

.package-table-card-heading{
    flex: 1;
    font-weight: 700;
    color: var(dark-text-color) !important;
}

.flex04{
    flex: 1;
}

.bold-text{
    font-weight: bold;
}

.split-payment-button{
    background-color: var(--primary-color);
    color: var(--white-color);
    font-weight: 500;
    border-radius: 7px;
    padding: 10px 1rem;
    text-align: initial;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.mobile-dashboard-container{
    display: none;
}

@media screen and (max-width: 1200px) {
    .package-table-heading {
        flex: 1 1;
        color: var(--primary-color);
        text-align: initial;
        font-size: 0.9rem;
    }

    .request-photos-button {
        gap: 0rem;
    }

    .request-photo-button-text{
        font-size: 0.9rem;
    }

    .button-text{
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 992px) {
    .packages-list-and-package-details{
        display: none;
    }

    .mobile-dashboard-container{
        display: block;
    }
}
