.button{
  padding: 2px;
}
.login-with-google-button:hover{
  background-color: var(--white-hover-color)
}

.googleBtn img {
  padding-right: 5px;
}

@media (min-width: 980px) and (max-width: 1160px) {
  
}

@media (min-width: 360px) and (max-width: 950px) {
  .googleBtn {
    padding: 1.2rem 1rem 1rem 1rem;
  }
}

.modalImg {
  width: 100%;
}

.countryText {
  color: #2463AF;
  font-weight: bolder;
  text-align: center;
  margin-top: -1.5rem;
}

.alertText {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.alertText > p {
  width: 70%;
}

.countrySelect {
  display: flex;
  justify-content: center;
}

.countrySelect select {
  width: 100%;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  background-color: white;
}

.btnDiv {
  display: flex;
  justify-content: center;
}

.btnDiv button {  
  background-color: #2463AF;
  padding: 1rem 3rem;
  font-weight: bold;
}

.facebook-login{
  border-radius: 36px;
}

.kep-login-facebook{
 border-radius: 36px!important;
 font-size: 20px;
}

.facebook-button{
  flex: 1;
  border-radius: 36px;
}