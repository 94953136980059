.footer-links{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;

}

.footer-container{
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    width: 98%;
}
.footer-link{
    text-decoration: none;
    font-size: 12px;
}
.footer-links img{
    width: 18px;
    /* margin-right: 10px; */
}
.footer-links .img-phone{
    width: 16px;
}


@media screen and (max-width: 992px) {
    .footer-container{
        position: static;
    }

    .footer-links{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin: 1rem;
        margin-top: 2rem;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 500px) {
    .footer-links{
        gap: 5px;
    }
}