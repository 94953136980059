@import url('../../App.css');

.recent-activity-container{
    margin-top: 10px;
}

.page-headings{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-box{
    width: 12rem;
    padding: 8px 10px;
    box-shadow: 0px 3px 5px rgba(0,0,0,0.2);
    border: 1px solid var(--border-color);
    border-radius: 7px;
    text-align: initial;
}

.recent-activities-container{
    box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    padding: 2rem;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 10px;
}

.page-activity-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 10px;
}

.activity-image-heading-content{
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 70%;
}

.activity-heading-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.activity-heading, .activity-content{
    text-align: initial;
}

.activity-heading{
    font-weight: 600;
}

.page-activity-img{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background: var(--lite-secondary-color);
}

.recent-activity-date-input{
    border-radius: 7px;
    border: 1px solid black;
    padding: 5px 1rem;
    cursor: pointer;
}

.activity-image{
    width: 1.3rem;
}

@media screen and (max-width: 992px) {
    .activity-image-heading-content {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 70%;
    }

    .recent-activities-container{
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        padding: 1rem;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 10px;
    }

    .recent-activity-container .checkout-calc-subheading {
        font-size: 0.9rem;
    }

    .activity-heading-content{
        font-size: 0.9rem;
    }

    .page-activity-img{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        background: var(--lite-secondary-color);
    }

    .activity-image{
        width: 0.9rem;
    }
}